import styled from "styled-components"
import { SectionHeader } from "../../../atoms"
import { SetStateAction, useRef, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import useScreenWidth from "../../../../utils/useScreenWidth"

const Wrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 7em 0;
  @media (max-width: 786px) {
    padding: 5em 0 6rem 0;
  }
  .sales {
    padding-bottom: 2em;
  }
  .slick-slider {
    padding: 2em;
    .slick-track {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .slick-dots {
      pointer-events: none;
    }
    .slick-slide {
      img {
        width: 200px;
        @media (max-width: 1200px) {
          width: 160px;
        }
        @media (max-width: 1024px) {
          width: 180px;
        }
        @media (max-width: 900px) {
          width: 140px;
        }
        @media (max-width: 750px) {
          width: 160px;
        }
        @media (max-width: 650px) {
          width: 140px;
        }
        @media (max-width: 550px) {
          width: 130px;
        }
        @media (max-width: 500px) {
          width: 130px;
        }
        @media (max-width: 450px) {
          width: 150px;
        }
      }
    }
  }
  .fade-in {
    animation: rotateAnimation ease 2500ms infinite;
  }

  @keyframes rotateAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.05;
    }
  }
`

const SalesAgentSection: React.FC = () => {
  const imageRef = useRef()
  const [currentSlideFirst, setCurrentSlideFirst] = useState(0)
  const [currentSlideSecond, setCurrentSlideSecond] = useState(0)
  const [currentSlideThird, setCurrentSlideThird] = useState(0)

  const screenSize = useScreenWidth()
  const slider1Ref = useRef(null)
  const slider2Ref = useRef(null)
  const slider3Ref = useRef(null)

  const responsiveSlider = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ]

  const settingsFirst = {
    lazyRender: true,
    infinite: true,
    swipe: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    easing: "easeIn",
    autoplaySpeed: 1600,
    afterChange: (index: SetStateAction<number>) => setCurrentSlideFirst(index),
    beforeChange: (_: number, newIndex: number) => {
      if (slider2Ref.current) {
        slider2Ref.current.slickGoTo(newIndex)
      }
      if (screenSize < 1024 && slider3Ref.current) {
        slider3Ref.current.slickGoTo(newIndex)
      }
    },
    responsive: responsiveSlider,
  }

  const settingsSecond = {
    lazyRender: true,
    infinite: true,
    swipe: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    easing: "easeIn",
    autoplaySpeed: 1600,
    afterChange: (index) => setCurrentSlideSecond(index),
    beforeChange: (_, newIndex) => {
      if (slider1Ref.current) {
        slider1Ref.current.slickGoTo(newIndex)
      }
      if (screenSize < 1024 && slider3Ref.current) {
        slider3Ref.current.slickGoTo(newIndex)
      }
    },
    responsive: responsiveSlider,
  }

  const settingsThird = {
    lazyRender: true,
    infinite: true,
    swipe: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    easing: "easeIn",
    autoplaySpeed: 1600,
    afterChange: (index: SetStateAction<number>) => setCurrentSlideThird(index),
    beforeChange: (_: any, newIndex: any) => {
      if (slider1Ref.current) {
        slider1Ref.current.slickGoTo(newIndex)
      }
      if (slider2Ref.current) {
        slider2Ref.current.slickGoTo(newIndex)
      }
    },
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }

  const imageList = [
    "/assets/images/salesagent1.svg",
    "/assets/images/salesagent2.svg",
    "/assets/images/salesagent3.svg",
    "/assets/images/salesagent4.svg",
    "/assets/images/salesagent5.svg",
    "/assets/images/salesagent11.svg",
    "/assets/images/salesagent13.svg",
    "/assets/images/salesagent15.svg",
  ]

  const imageListSecond = [
    "/assets/images/salesagent6.svg",
    "/assets/images/salesagent7.svg",
    "/assets/images/salesagent8.svg",
    "/assets/images/salesagent9.svg",
    "/assets/images/salesagent10.svg",
    "/assets/images/salesagent12.svg",
    "/assets/images/salesagent14.svg",
    "/assets/images/salesagent16.png",
  ]

  const imageListMobile = [
    "/assets/images/salesagent1.svg",
    "/assets/images/salesagent2.svg",
    "/assets/images/salesagent3.svg",
    "/assets/images/salesagent4.svg",
    "/assets/images/salesagent5.svg",
  ]

  const imageListMobileSecond = [
    "/assets/images/salesagent6.svg",
    "/assets/images/salesagent7.svg",
    "/assets/images/salesagent8.svg",
    "/assets/images/salesagent9.svg",
    "/assets/images/salesagent10.svg",
  ]

  const imageListMobileThird = [
    "/assets/images/salesagent11.svg",
    "/assets/images/salesagent12.svg",
    "/assets/images/salesagent13.svg",
    "/assets/images/salesagent15.svg",
    "/assets/images/salesagent14.svg",
  ]

  return (
    <Wrapper id="sales-agents">
      <SectionHeader
        icon={"sales"}
        title="Sales Agentの紹介先例"
        className="sales"
      />
      <Slider {...settingsFirst} ref={slider1Ref}>
        {(screenSize < 1024 ? imageListMobile : imageList)?.map(
          (image, index) => (
            <div key={index}>
              <img
                ref={imageRef}
                src={image}
                key={index}
                alt={"sales agent-" + index}
                loading="lazy"
                className={`slider-item ${
                  index === currentSlideFirst ? "fade-in" : ""
                }`}
              />
            </div>
          )
        )}
      </Slider>
      <Slider {...settingsSecond} ref={slider2Ref}>
        {(screenSize < 1024 ? imageListMobileSecond : imageListSecond)?.map(
          (image, index) => (
            <div key={index}>
              <img
                ref={imageRef}
                src={image}
                alt={"sales agent-" + index}
                loading="lazy"
                className={`slider-item ${
                  index === currentSlideSecond ? "fade-in" : ""
                }`}
              />
            </div>
          )
        )}
      </Slider>
      {screenSize < 1024 && (
        <Slider {...settingsThird} ref={slider3Ref}>
          {imageListMobileThird?.map((image, index) => (
            <div key={index}>
              <img
                ref={imageRef}
                src={image}
                alt={"sales agent-" + index}
                loading="lazy"
                className={`slider-item ${
                  index === currentSlideThird ? "fade-in" : ""
                }`}
              />
            </div>
          ))}
        </Slider>
      )}
    </Wrapper>
  )
}

export { SalesAgentSection }
