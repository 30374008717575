import {
  CalendarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons"
import React from "react"
import styled from "styled-components"
import { ReasonCard } from "../../../molecules"

const Wrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 50px 0;
  color: #000f23;
  background-color: #f7f8f9;
`

const Title = styled.div`
  font-size: 1.2rem;
  text-align: center;
  font-weight: 800;
  line-height: 26px;
  margin-bottom: 20px;
  @media (min-width: 478px) {
    font-size: 18px;
  }
`

const SubTitle = styled.div`
  font-size: 25px;
  text-align: center;
  font-weight: 800;
  @media (min-width: 478px) {
    font-size: 35px;
  }
`
const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 1060px;
  margin: auto;
  @media (max-width: 1095px) {
    font-size: 35px;
    grid-template-columns: 1fr;
    width: 340px;
  }
`

const reasonData = [
  {
    title: "営業特化型の求人データベース",
    smybol: <ClockCircleOutlined />,
    content: [
      "営業に特化した求人の活用が可能",
      "企業閉拓の時間工数削減",
      "業界特化で成約率向上",
    ],
  },
  {
    title: "業務管理機能",
    smybol: <CheckOutlined />,
    content: [
      "求職者の登録から書類管理、ステータスまで管理",
      "エントリー後の選考対応も全て１ツール内で完結",
    ],
  },
  {
    title: "業界経験者による人材紹介事業サポート",
    smybol: <CalendarOutlined />,
    content: [
      "サービス利用後の人材紹介事業サポートまで実施",
      "集客から内定承諾の客プロセスの改善も可能",
    ],
  },
]

const ReasonSection: React.FC = () => {
  return (
    <Wrapper>
      <Title>Why Choose Me</Title>
      <SubTitle>Sales agent 3つの価値</SubTitle>
      <CardWrapper>
        {reasonData.map((data, index) => (
          <ReasonCard
            title={data.title}
            symbol={data.smybol}
            content={data.content}
            key={index}
          />
        ))}
      </CardWrapper>
    </Wrapper>
  )
}

export { ReasonSection }
