import React, { useState } from "react"
import styled from "styled-components"
import Link from "next/link"
import Router from "next/router"
import { Button } from "@project/shared"
import { CloseOutlined, MenuOutlined } from "@ant-design/icons"
import { Link as ScrollLink } from "react-scroll"

interface iHeader {
  noButtons?: boolean
  backgroundColor?: string
}

const Header = styled.header<iHeader>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#ffffff"};
  & .main-header-wrapper {
    display: flex;
    padding: 1em;
    align-items: center;
    justify-content: space-between;
    max-width: 1480px;
    margin: 0 auto;
    padding-left: 4.5em;
    padding-right: 4.5em;
    .header-logo {
      width: 240px;
      height: 54.38px;
      @media (max-width: 400px) {
        width: 200px;
      }
    }
  }
  & .mobile-menu {
    transition: all 0.5s;
    overflow: hidden;
    display: none;
    & .menu-navs {
      padding-left: 20px;
      padding-right: 20px;
      background: #f5f5f5;
      border-top: 1px solid #eaeaea;
      padding-top: 10px;
      padding-bottom: 10px;
      color: #5d7280;
      a {
        font-size: 14px;
        color: #5d7280;
      }
    }
  }
  @media (max-width: 980px) {
    & .main-header-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
    & .mobile-menu {
      display: block;
    }
  }
`

const RightContent = styled.div<iHeader>`
  display: ${({ noButtons }) => (noButtons ? "none" : "block")};
  & .desktop-btns {
    display: flex;
    @media (max-width: 980px) {
      display: none !important;
    }
    & .inquiry-btn {
      margin-left: 15px;
    }
    & .privacy-policy-btn {
      margin-left: 15px;
    }
  }
  & .mobile-menu-btn {
    display: grid;
    place-items: center;
    height: 40px;
    width: 40px;
    padding: 0;
    & .anticon {
      color: #fff;
      font-size: 20px;
    }
    @media (min-width: 979px) {
      display: none !important;
    }
  }
`

const HeaderComponent: React.FC<iHeader> = ({ noButtons, backgroundColor }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen((prevState) => !prevState)
  }
  return (
    <Header backgroundColor={backgroundColor}>
      <div className="main-header-wrapper">
        <a href="/">
          <img
            src="/assets/icons/logo.gif"
            className={"header-logo"}
            alt="Sales agent logo"
          />
        </a>
        <RightContent noButtons={noButtons}>
          <div className="desktop-btns">
            <Button
              width="140px"
              borderradius="50px"
              minheight={44}
              fontSize="18px"
              className="login-btn"
              onClick={() => {
                window.open(
                  `${process.env.NEXT_PUBLIC_AGENT_HOST}/login`,
                  "_blank"
                )
              }}
            >
              ログイン
            </Button>
            <ScrollLink to="inquiry-section" smooth={true} duration={500}>
              <Button
                width="140px"
                borderradius="50px"
                minheight={44}
                fontSize="18px"
                className="inquiry-btn"
              >
                お問い合わせ
              </Button>
            </ScrollLink>
            <Button
              width="210px"
              borderradius="50px"
              minheight={44}
              fontSize="18px"
              className="privacy-policy-btn"
              onClick={() => Router.push("/privacy-policy")}
            >
              プライバシーポリシー
            </Button>
          </div>
          <Button className="mobile-menu-btn" onClick={handleMobileMenuToggle}>
            {mobileMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
          </Button>
        </RightContent>
      </div>
      <div
        className="mobile-menu"
        style={{ height: mobileMenuOpen ? "128px" : "0px" }}
      >
        <div className="menu-navs">
          <Link href={`${process.env.NEXT_PUBLIC_AGENT_HOST}/login`}>
            <a target="_blank" rel="noreferrer">
              ログイン
            </a>
          </Link>
        </div>
        <ScrollLink to="inquiry-section" smooth={true} duration={500}>
          <div className="menu-navs">お問い合わせ</div>
        </ScrollLink>
        <div className="menu-navs">
          <Link href="/privacy-policy">プライバシーポリシー</Link>
        </div>
      </div>
    </Header>
  )
}

export { HeaderComponent as Header }
