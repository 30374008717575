import React from "react"
import { AgentReasonCard, SectionHeader } from "../../atoms"

const ReasonsToChoose = () => {
  const reasons = [
    {
      title: "母集団形成の量と質を担保",
      detail: `セールスポジションに特化、成約を出して\nいる紹介会社を当社で選んでいるため、\n紹介の質を担保することが可能です。\n各紹介会社によって集客の得意な層が\n異なりますので、それぞれにカスタマイズ\nしながら貴社の求人を周知いたします。`,
      image: "/assets/images/quality.png",
      coloumReverse: false,
      reasonImage: "/assets/images/reason-one.png",
      reasonImageStright: "/assets/images/reason-one-straight.png",
    },
    {
      title: "細かいヒアリングによる密な連携",
      detail:
        "月次〜四半期に一度のペースで採用の\nご状況を採用担当の方とすり合わ\nせております。求人内容には記載しにくい\n内容なども伺い目線合わせを\n行います。その一度で人材会社100社に同じ\n内容を共有できますので採用\n担当の業務を減らしながら紹介数や質を\n担保してご紹介が可能です。",
      image: "/assets/images/co-operation.png",
      coloumReverse: true,
      reasonImage: "/assets/images/reason-two.png",
      reasonImageStright: "/assets/images/reason-two-straight.png",
    },
    {
      title: "紹介の質がすりあうまでの時間を短縮",
      detail:
        "通常であれば、各人材会社に一からお見送\nり理由などをフィードバックしますが、\n当社がデータベースで一括で共有します\nので使っていただくほどに知見がたまり\n紹介の質を上げやすくなります。また、\n当社のミッションである「なりたい自分に\n合う」に共感していただいている紹介\n会社様が多いので、ただ人をご紹介するの\nではなく、その求職者に寄り添いキャリア\nを一緒につくりながらも企業の採用要件に\n合う方をご紹介いたします。",
      image: "/assets/images/referral-quality.png",
      coloumReverse: false,
      reasonImage: "/assets/images/reason-three.png",
      reasonImageStright: "/assets/images/reason-three-straight.png",
    },
  ]

  return (
    <div id="sales-agents" style={{ paddingTop: 20 }}>
      <div style={{ marginTop: 40 }}>
        <SectionHeader icon="sales" title="Sales Agentが選ばれる3つの理由" />
      </div>
      {reasons.map((item, index) => (
        <AgentReasonCard reasonDetails={item} key={index} />
      ))}
    </div>
  )
}

export { ReasonsToChoose }
