import React, { useEffect } from "react"
import { useRouter } from "next/router"

const useRestrictedRoute = (AuthComponent) => {
  const RestrictedComponent = ({ children }) => {
    const router = useRouter()
    useEffect(() => {
      const access = localStorage.getItem("direct-access")
      if (!access) {
        router.back()
        localStorage.setItem("direct-access", "")
      }
    }, [])

    return children
  }

  return class Higher extends React.Component {
    render() {
      return (
        <RestrictedComponent>
          <AuthComponent {...this.props} />
        </RestrictedComponent>
      )
    }
  }
}

export { useRestrictedRoute }
