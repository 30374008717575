import React from "react"
import styled from "styled-components"
import { iServiceItem } from "../../../../interface/serviceItem"
import { serviceItems } from "../../../../services/serviceItems"
import { ServiceItem } from "../../../molecules/LandingPage/ServiceItem"

const Wrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 50px 20px 60px 20px;
  color: #000f23;
  @media (min-width: 900px) {
    padding: 125px 10% 75px 10%;
  }
  @media (min-width: 1200px) {
    padding: 125px 15% 75px 15%;
  }
`

const Title = styled.div`
  font-size: 1.2rem;
  text-align: center;
  font-weight: 800;
  line-height: 26px;
  margin-bottom: 20px;
  @media (min-width: 478px) {
    font-size: 18px;
  }
`

const SubTitle = styled.div`
  font-size: 25px;
  text-align: center;
  font-weight: 800;
  @media (min-width: 478px) {
    font-size: 35px;
  }
`

const ServiceSection: React.FC = () => {
  return (
    <Wrapper>
      <Title>Services</Title>
      <SubTitle>Sales agentとは</SubTitle>
      {serviceItems.map((item: iServiceItem, index: number) => (
        <ServiceItem
          key={index}
          number={index + 1}
          imgSrc={item.imgSrc}
          title={item.title}
          content={item.content}
        />
      ))}
    </Wrapper>
  )
}

export { ServiceSection }
