import { theme } from "@project/shared"
import styled from "styled-components"

const ContainerSection = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 20px;
  .thank-you {
    font-weight: 700;
    font-size: 56px;
    color: ${theme.blueColouredText};
    padding-top: 2em;
  }
  .section-detail {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.08em;
    text-align: center;
    padding: 1.5em 0 2em 0;
    @media (max-width: 768px) {
      font-size: 18px;
    }
    @media (max-width: 390px) {
      font-size: 16px;
    }
  }
  .mailing-address {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.08em;
  }
`

const ImageOverlay = styled.div`
  position: relative;
  .overlay-image {
    width: 100vw;
    @media (max-width: 560px) {
      display: none;
    }
  }
  .overlay-image-second {
    width: 100vw;
    display: none;
    @media (max-width: 560px) {
      display: block;
      height: 250px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.blueColouredText};
    opacity: 0.3;
  }
  .overlay-text {
    font-weight: 700;
    font-size: 56px;
    line-height: 81px;
    letter-spacing: 0.04em;
    color: ${theme.base};
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    @media (max-width: 786px) {
      font-size: 26px;
      line-height: 40px;
    }
  }
`
const ThankYouSection: React.FC = () => {
  return (
    <ContainerSection>
      <ImageOverlay>
        <img
          src="/assets/images/thankyoubanner.png"
          className="overlay-image"
        />
        <img
          src="/assets/images/thankyoubanner-second.png"
          className="overlay-image-second"
        />
        <span className="overlay-text"> 資料ダウンロード </span>
      </ImageOverlay>
      <p className="thank-you">Thank You!</p>
      <p className="section-detail">
        {"資料ダウンロードいただき、誠にありがとうございます。"}
        <br />
        {"ご入力いただきましたメールアドレスに、資料を送付させていただきます。"}
        <br />
        {
          "ご質問・不明点等ございましたら、下記連絡先までお気軽にご連絡ください。"
        }
      </p>

      <p className="mailing-address">
        {"株式会社HRテクノロジーズ "}
        <br />
        {"Mail: info@hrtechnologies-inc.com"}
        <br />
        {"Tel: 090-9645-1117 (代表 鈴木宛)"}
      </p>
    </ContainerSection>
  )
}

export { ThankYouSection }
