import { Col, notification, Row } from "antd"
import { useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import * as yup from "yup"
import { postInquiry } from "../../../../services/inquiry"
import { useProgressiveImage } from "../../../../utils"
import dynamic from "next/dynamic"

const Button = dynamic(
  () => import("@project/shared").then((mod) => mod.Button),
  { ssr: false }
)
const TextField = dynamic(
  () => import("@project/shared").then((mod) => mod.TextField),
  { ssr: false }
)
const Wrapper = styled.div<any>`
  display: flex;
  justify-content: center;
  background-image: url(${({ src }) => src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 80px;
  margin-top: 80px;
  padding: 50px 50px 50px 50px;
  width: 60%;
  background-color: #ffff;
  box-sizing: border-box;
  border-radius: 20px 20px 20px 20px;

  @media (max-width: 768px) {
    border-radius: 50px 50px 50px 50px;
    padding-right: 30px;
    padding-left: 30px;
    margin-bottom: 30px;
    margin-top: 30px;
    width: 90%;
    text-align: left;
  }

  & .form-title {
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  & form {
    width: 100%;
  }

  & .form-class {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;

    & .form-item {
      margin-bottom: 40px;
      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
      label {
        font-weight: normal;
        font-size: 16px;
        span {
          color: #f00;
          margin-left: 10px;
        }
      }
      .input {
        margin-top: 15px;
      }
      .input-remarks textarea {
        max-height: 186px !important;
      }
    }
  }
  & .btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`

const InquiryForm = () => {
  const { t } = useTranslation()
  const lazySrc = useProgressiveImage("/assets/images/background-img.jpg")

  const initialValues = {
    name: "",
    furigana: "",
    email: "",
    phone: "",
    content: "",
  }

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .max(100, t("Maximum 100 characters allowed"))
      .required(t("Please enter")),
    furigana: yup
      .string()
      .max(100, t("Maximum 100 characters allowed"))
      .required(t("Please enter")),
    email: yup
      .string()
      .email(t("Invalid email format"))
      .max(50, t("Maximum 50 characters allowed"))
      .required(t("Please enter")),
    phone: yup
      .string()
      .required(t("Please enter"))
      .matches(/^[- +()]*[0-9][- +()0-9]*$/, t("number only")),
    content: yup
      .string()
      .max(500, t("Maximum 500 characters allowed"))
      .required(t("Please enter")),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      mutate({ ...values }, { onSuccess: () => resetForm() })
    },
  })

  const { mutate, isLoading } = useMutation(postInquiry, {
    onSuccess: () => {
      notification.success({
        message: t(
          "Inquiry form submitted successfully. A person in-charge will contact you"
        ),
      })
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("An error has occurred. Please try again later."),
      })
    },
  })
  return (
    <>
      <Wrapper id="inquiry-section" src={lazySrc}>
        <Form>
          <div className="form-title">お問い合わせフォーム</div>
          <div className="form-title">お気軽にお問い合わせください</div>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-class">
              <Row gutter={100}>
                <Col xs={24} xl={12}>
                  <div className="form-item">
                    <label>
                      {t("Name")}
                      <span>({t("Required")})</span>
                    </label>
                    <TextField
                      name="name"
                      value={formik.values.name}
                      type="text"
                      fullWidth={true}
                      height="40px"
                      onChange={formik.handleChange}
                      error={formik.touched.name && formik.errors?.name}
                      onBlur={formik.handleBlur}
                      className="input"
                      placeholder={`${"山田　太郎"}*`}
                      placeholdercolor="#9f9f9f"
                    ></TextField>
                  </div>
                </Col>
                <Col xs={24} xl={12}>
                  <div className="form-item">
                    <label>
                      {t("Furigana")}
                      <span>({t("Required")})</span>
                    </label>
                    <TextField
                      name="furigana"
                      value={formik.values.furigana}
                      type="text"
                      fullWidth={true}
                      height="40px"
                      onChange={formik.handleChange}
                      error={formik.touched.furigana && formik.errors?.furigana}
                      onBlur={formik.handleBlur}
                      className="input"
                      placeholder={`${"ヤマダ　タロウ"}*`}
                      placeholdercolor="#9f9f9f"
                    ></TextField>
                  </div>
                </Col>
              </Row>
              <Row gutter={100}>
                <Col xs={24} xl={12}>
                  <div className="form-item">
                    <label>
                      {t("Email address")}
                      <span>({t("Required")})</span>
                    </label>
                    <TextField
                      name="email"
                      value={formik.values.email}
                      type="email"
                      onChange={formik.handleChange}
                      error={formik.touched.email && formik.errors?.email}
                      onBlur={formik.handleBlur}
                      className="input"
                      height="40px"
                      placeholder={`${"someone@example.com"}*`}
                      placeholdercolor="#9f9f9f"
                    ></TextField>
                  </div>
                </Col>
                <Col xs={24} xl={12}>
                  <div className="form-item">
                    <label>
                      {t("Phone number")}
                      <span>({t("Required")})</span>
                    </label>
                    <TextField
                      name="phone"
                      value={formik.values.phone}
                      type="text"
                      height="40px"
                      onChange={formik.handleChange}
                      error={formik.touched.phone && formik.errors?.phone}
                      onBlur={formik.handleBlur}
                      className="input"
                      placeholder={`${"08000000000"}*`}
                      placeholdercolor="#9f9f9f"
                    ></TextField>
                  </div>
                </Col>
              </Row>
              <Row gutter={100}>
                <Col span={24}>
                  <div className="form-item">
                    <label>
                      {t("Inquiry contents")}
                      <span>({t("Required")})</span>
                    </label>
                    <TextField
                      name="content"
                      value={formik.values.content}
                      type="textarea"
                      rows={8}
                      onChange={formik.handleChange}
                      error={formik.touched.content && formik.errors?.content}
                      onBlur={formik.handleBlur}
                      className="input input-remarks"
                      placeholder={`${"お問い合わせ内容を入力してください"}*`}
                      placeholdercolor="#9f9f9f"
                    ></TextField>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="btn">
              <Button
                htmlType="submit"
                width="13rem"
                borderradius="20px"
                loading={isLoading}
              >
                確認して送信
              </Button>
            </div>
          </form>
        </Form>
      </Wrapper>
    </>
  )
}

export { InquiryForm }
