import { CaretDownFilled } from "@ant-design/icons"
import { theme } from "@project/shared"
import { Input, Select } from "antd"
import styled from "styled-components"

const Wrapper = styled.div`
  .ant-input {
    height: 70px;
    background: ${theme.cardGreyBg};
    width: 510px;
    border-radius: 8px;
    @media (max-width: 550px) {
      width: 90vw;
      height: 60px;
      font-size: 18px;
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
  }
`
const Label = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 15px;
  span {
    color: red;
  }
`
const SelectWrapper = styled.div`
  .ant-select-single .ant-select-selector {
    width: 510px;
    height: 70px;
    background: ${theme.cardGreyBg};
    border-radius: 8px;
    display: flex;
    align-items: center;
    @media (max-width: 550px) {
      width: 90vw;
      height: 60px;
      font-size: 16px;
    }
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    @media (max-width: 550px) {
      font-size: 16px;
    }
  }
  .ant-select-arrow {
    top: 25px;
    right: 40px;
    color: black;
    svg {
      width: 35px;
      height: 35px;
    }
    @media (max-width: 550px) {
      top: 20px;
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
  }
`

const ErrorStyled = styled.span`
  font-size: 12px;
  color: ${theme.alert};
  margin-top: 2px;
  margin-left: 2px;
`

interface Props {
  type?: "text" | "select"
  label?: string
  required?: boolean
  onChange?: any
  name?: string
  value?: string
  error?: string
  options?: any[]
}
const TextField: React.FC<Props> = ({
  label,
  name,
  required = false,
  onChange,
  type = "text",
  value,
  error,
  options,
}) => {
  if (type === "select") {
    return (
      <SelectWrapper>
        <Label>
          {label} {required && <span>*</span>}
        </Label>
        <div className="text-wrapper">
          <Select
            options={options}
            onChange={onChange}
            value={value}
            suffixIcon={<CaretDownFilled />}
          />
          {error && <ErrorStyled>{error}</ErrorStyled>}
        </div>
      </SelectWrapper>
    )
  }
  return (
    <Wrapper>
      <Label>
        {label} {required && <span>*</span>}
      </Label>
      <div className="text-wrapper">
        <Input name={name} onChange={onChange} value={value} />
        {error && <ErrorStyled>{error}</ErrorStyled>}
      </div>
    </Wrapper>
  )
}
export { TextField }
