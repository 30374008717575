import { theme } from "@project/shared"
import { useRouter } from "next/router"
import styled from "styled-components"

interface IInformationCard {
  title?: string
  description?: string
  bgColor?: string
  linkText?: string
  titleHeight?: string
  descriptionFontSize?: string
  titleFontSize?: string
  borderRadius?: string
  titleWidth?: string
  cardWidth?: string
  top?: string
  responsive?: boolean
}

const CardWrapper = styled.div<any>`
  width: ${({ cardWidth }: IInformationCard) =>
    cardWidth ? cardWidth : "304px"};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "20px"};
  ${({ responsive }) =>
    responsive &&
    `
      @media (max-width: 768px) {
        width: 220px;
      }
      @media (max-width: 500px) {
        width: 169px;
        height: 143px;
      }
    `}

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ bgColor }) =>
    bgColor ? bgColor : theme.infromationCardBG};
  margin-top: 37px;
  z-index: 2;
  .description {
    padding: ${({ linkText }) =>
      linkText ? "78px 20px 0px 20px" : "78px 20px 50px 20px"};
    margin: 0 auto;
    font-size: ${({ descriptionFontSize }: IInformationCard) =>
      descriptionFontSize ? descriptionFontSize : "20px"};
    font-weight: 700;
    color: ${theme.cardDescriptionColor};
    text-align: center;
    white-space: break-spaces;
    ${({ responsive }) =>
      responsive &&
      `
      @media (max-width: 768px) {
        font-size: 20px;
        width: 230px;
        
      }
      @media (max-width: 500px) {
        font-size: 14px;
        width: 169px;
      }
    `}
  }
`
const CardTitle = styled.div<any>`
  width: ${({ titleWidth }: IInformationCard) =>
    titleWidth ? titleWidth : "231px"};
  height: ${({ titleHeight }: IInformationCard) =>
    titleHeight ? titleHeight : "73.84px"};
  border-radius: 100px;
  background-color: ${theme.cardDescriptionColor};
  position: absolute;
  top: ${({ top }: IInformationCard) => (top ? top : "-30px")};
  ${({ responsive }) =>
    responsive &&
    `
     @media (max-width: 768px) {
        width: 180px;
      }
      @media (max-width: 500px) {
         width: 140px;
      }
    `}
  .title {
    margin: 0 auto;
    position: relative;
    display: flex;
    height: ${({ titleHeight }: IInformationCard) =>
      titleHeight ? titleHeight : "73.84px"};
    justify-content: center;
    align-items: center;
    font-size: ${({ titleFontSize }) =>
      titleFontSize ? titleFontSize : "24px"};
    font-weight: 700;
    color: ${() => theme.base};
    word-break: break-all;
    text-align: center;
    padding-inline: 1em;

    ${({ responsive }) =>
      responsive &&
      `
     @media (max-width: 768px) {
        font-size: 15px;
      }
      @media (max-width: 500px) {
        font-size: 10px;
      }
    `}
  }
`
const CardLink = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 20px;
  a {
    color: ${() => theme.linkColor};
    font-size: 16px;
    text-decoration: underline;
  }
`

export const InformationCard: React.FC<IInformationCard> = ({
  title,
  description,
  bgColor,
  linkText,
  titleFontSize,
  titleHeight,
  descriptionFontSize,
  borderRadius,
  cardWidth,
  titleWidth,
  top,
  responsive = false,
}) => {
  const router = useRouter()
  return (
    <CardWrapper
      borderRadius={borderRadius}
      bgColor={bgColor}
      linkText={linkText}
      descriptionFontSize={descriptionFontSize}
      cardWidth={cardWidth}
      responsive={responsive}
    >
      <CardTitle
        titleHeight={titleHeight}
        titleFontSize={titleFontSize}
        titleWidth={titleWidth}
        top={top}
        responsive={responsive}
      >
        <p className="title">{title}</p>
      </CardTitle>
      <p className="description">{description}</p>
      {linkText && (
        <CardLink>
          <a
            href={
              router.pathname?.includes("company")
                ? "http://sales-agent.co.jp"
                : "http://sales-agent.co.jp/company"
            }
            target="_blank"
          >
            {linkText}
          </a>
        </CardLink>
      )}
    </CardWrapper>
  )
}
