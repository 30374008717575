import styled from "styled-components"
import BorderCard from "../BorderCard"
import { SectionHeader } from "../../../atoms"
const Wrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  width: auto;
  padding-top: 10%;
`
const Content = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 5em;
  padding: 20px;
  @media only screen and (max-width: 850px) {
    margin-top: 3em;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    img {
      transform: rotate(90deg);
      height: 50px;
    }
  }
`
const SignificantReduction = () => {
  return (
    <Wrapper>
      <SectionHeader
        icon="personal-costs"
        title="圧倒的な人事コストの削減ができます"
      />
      <Content>
        <BorderCard
          title="管理に時間を割かれる人事"
          headline="Before"
          border_color="#AAAAAA"
          heading_bg="#AAAAAA"
          title_color="#AAAAAA"
        >{`・返金規定や手数料のばらつき \n・複数の連絡ツールによる抜け漏れの発生、引き継ぎができない`}</BorderCard>
        <img src="/assets/icons/arrow.svg" />
        <BorderCard
          title="本質的な業務に時間を割ける"
          headline="After"
        >{`事務作業を軽減することで要件設定や\n選考フォロー、他業務など必要なことに\n時間を割けるようになります。`}</BorderCard>
      </Content>
    </Wrapper>
  )
}
export { SignificantReduction }
