import React from "react"
import styled from "styled-components"
import { iReasonData } from "../../../../interface/reasondata"

const Wrapper = styled.div`
  width: 340px;
  height: 387px;
  margin: auto;
  padding: 50px 25px 55px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 0px 45px -10px rgb(93, 114, 128, 0.1);
  svg {
    width: 50px !important;
    height: 50px !important;
  }
`
const Title = styled.div`
  font-size: 20px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
  min-height: 64px;
`
const Content = styled.ul`
  font-size: 15px;
  color: #5d7280;
  padding: 0;
  width: 265px;
`
const ReasonCard: React.FC<iReasonData> = ({ title, symbol, content }) => {
  return (
    <Wrapper>
      {symbol}
      <Title>{title}</Title>
      <Content>
        {content.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </Content>
    </Wrapper>
  )
}

export { ReasonCard }
