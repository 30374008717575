import { useRouter } from "next/router"
import styled from "styled-components"

const Wrapper = styled.div`
  background: #2b57a5;
  padding: 100px 50px;
  .side-content {
    display: flex;
    color: white;
    justify-content: end;
    font-size: 14px;
    font-weight: 400;
    margin-top: 40px;
  }
  @media (max-width: 970px) {
    padding: 30px;
    .side-content {
      font-size: 12px;
      justify-content: center;
      margin-top: 20px;
    }
  }
`
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 970px) {
    flex-direction: column;
  }
`
const SideContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  color: white;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  p {
    margin-bottom: 0px !important;
    margin-right: 20px;
  }
  @media (max-width: 970px) {
    margin-top: 20px;
    justify-content: center;
    img {
      height: 65px;
      width: 150px;
    }
    font-size: 16px;
    gap: 20px;
  }
`
const FooterBottom = styled.div`
  background: #23385d;
  padding: 10px;
  text-align: center;
  color: white;
  font-size: 12px;
  font-weight: 400;
`
const FooterSection = () => {
  const router = useRouter()
  const { from } = router?.query
  const isCompanyPage = router.pathname.includes("company")

  const menuItem =
    from === "company" || isCompanyPage
      ? [
          { label: "Sales Agentとは?", route: "connect-companies" },
          { label: "利用料金", route: "performance-reward" },
          { label: "選ばれる理由", route: "sales-agents" },
          { label: "機能紹介", route: "function-introduction" },
          { label: "資料ダウンロード", route: "download-documents" },
        ]
      : [
          { label: "Sales Agentとは?", route: "connect-companies" },
          { label: "紹介先例", route: "sales-agents" },
          { label: "機能紹介", route: "function-introduction" },
          { label: "他社との比較", route: "comparison" },
          { label: "資料ダウンロード", route: "download-documents" },
        ]

  const gotoSection = (sectionID) => {
    const section = document.getElementById(sectionID)
    if (section) {
      section.scrollIntoView({ behavior: "smooth" })
    }
  }
  return (
    <>
      <Wrapper>
        <Content>
          <img src={"/assets/icons/footer-logo.svg"} />

          <SideContent>
            {menuItem?.map((item, index) => (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  router.pathname.includes("/thanks")
                    ? router.push(`/${from}#${item.route}`)
                    : gotoSection(item?.route)
                }}
                key={index}
              >
                {item?.label}
              </p>
            ))}
          </SideContent>
        </Content>
        <div className="side-content">運営企業</div>
      </Wrapper>
      <FooterBottom>©2022- ︎HRテクノロジーズ All Rights Reserved.</FooterBottom>
    </>
  )
}
export { FooterSection }
