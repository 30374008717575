import React from "react"
import styled from "styled-components"

const FooterText = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 60px;
  @media (max-width: 786px) {
    font-size: 16px;
    padding-bottom: 40px;
  }
`

const Wrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 120px 0 14px 0;
  @media (max-width: 786px) {
    padding: 80px 0 0 0;
  }
`

const Title = styled.div`
  font-size: 35px;
  text-align: center;
  font-weight: 800;
  margin-bottom: 50px;
  @media (max-width: 786px) {
    font-size: 25px;
    margin-bottom: 30px;
  }
`
const ImageWrapper = styled.div`
  max-width: 786px;
  margin: auto;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  img {
    width: 200px;
    object-fit: contain;
    height: auto;
  }
  @media (max-width: 800px) {
    max-width: 440px;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
  }
  @media (max-width: 450px) {
    max-width: 200px;
    grid-template-columns: 1fr;
    row-gap: 40px;
  }
`

const imageList: Array<string> = [
  "/assets/images/company1.jpg",
  "/assets/images/company2.jpg",
  "/assets/images/company3.jpg",
  "/assets/images/company4.jpg",
  "/assets/images/company5.jpg",
  "/assets/images/company6.jpg",
  "/assets/images/company7.jpg",
  "/assets/images/company8.jpg",
  "/assets/images/company9.jpg",
]
const CompanySection: React.FC = () => {
  return (
    <>
      <Wrapper>
        <Title>幅広い厳選された営業求人案件</Title>
        <ImageWrapper>
          {imageList.map((image, index) => (
            <img
              src={image}
              key={index}
              width={200}
              height={200}
              alt={"company-" + index}
              loading="lazy"
            />
          ))}
        </ImageWrapper>
      </Wrapper>
      <FooterText>
        そのほか、スタートアップ、中小企業からメガベンチャーまで幅広く実績があります。
      </FooterText>
    </>
  )
}

export { CompanySection }
