import styled from "styled-components"
import useScreenWidth from "../../../../utils/useScreenWidth"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 10em;
  @media (max-width: 768px) {
    padding-top: 5em;
  }
`
const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0.04em;
  @media (max-width: 1054px) {
    font-size: 26px;
    white-space: break-spaces;
  }
  @media (max-width: 768px) {
    font-size: 24px;
  }
`
const Content = styled.div`
  padding: 10px;
  p {
    margin-top: 56px;
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: 0.08em;
    @media (max-width: 1054px) {
      margin-top: 30px;
      font-size: 17px;
      text-align: center;
      white-space: break-spaces;
    }
  }
`
const RecrumentAgency = () => {
  const screenWidth = useScreenWidth()
  return (
    <Wrapper>
      <Content>
        <Title>
          {"当社が厳選した人材紹介会社約\n100社から\n"}
          {screenWidth > 1054 && <br />}
          {"要望にあった営業人材に効率\nよく出会えます!"}
        </Title>
        <p>
          {"求職者に寄り添っている人材紹介ほどRA\n業務にさく時間がない..."}
          <br />
          {
            "採用企業もどの人材紹介が自社に合う人材\nを紹介してくれるかわからない.."
          }
          <br />
          {"採用要件も毎回同じことを何十社に\nもしゃべる..."}
          <br />
          {"営業職はどこの企業にも必要なのに活躍\nする人材を獲得できない..。"}
          <br />
          {"人材会社と求人企業にとっていい形で\n関われないか?"}
          <br />
          {
            "弊社代表鈴木が今まで大手人材会社での\n営業や中小企業の採用責任者などを"
          }
          <br />
          {" 歴任してきて感じた課題感からこの\nサービスは生まれました。"}
        </p>
      </Content>
    </Wrapper>
  )
}
export { RecrumentAgency }
