import styled from "styled-components"

interface Props {
  type?: "amount-number" | "amount-text"
  amount?: number | string
  title?: string
  headline: string
  border_color?: string
  heading_bg?: string
  title_color?: string
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  @media (max-width: 850px) {
    width: 370px;
  }
  .restricted {
    @media (max-width: 850px) {
      height: 180px;
      width: 300px;
    }
  }
`
const Container = styled.div<any>`
  width: 100%;
  border: 4px solid;
  border-color: ${({ border_color }) =>
    border_color ? border_color : "#3c70cd"};
  height: 240px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3c70cd;
  font-weight: 700;
  .amount {
    font-size: 160px;
  }
  .price {
    font-size: 36px;
    bottom: 0px !important;
  }
  @media only screen and (max-width: 850px) {
    width: 370px;
    .amount {
      font-size: 100px;
    }
  }
`
const Heading = styled.div<any>`
  background: ${({ heading_bg }) => (heading_bg ? heading_bg : "#3c70cd")};
  border-radius: 100px;
  padding: 0px 65px;
  display: flex;
  justify-content: center;
  line-height: 45px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  position: absolute;
  z-index: 1;
  margin-top: -20px;
`
const Content = styled.div<any>`
  width: 400px;
  border: 4px solid;
  border-color: ${({ border_color }) =>
    border_color ? border_color : "#3c70cd"};
  height: 240px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3c70cd;
  font-weight: 700;
  font-size: 72px;
  letter-spacing: 0.08em;
  @media only screen and (max-width: 1228px) {
    font-size: 52px;
  }
  @media only screen and (max-width: 850px) {
    font-size: 40px;
  }
`
const ContainerContent = styled.div`
  padding-top: 40px;
  @media (max-width: 768px) {
    padding-top: 20px;
  }

  .children {
    margin: 20px;
    color: black;
    white-space: break-spaces;
    font-weight: 400;
    font-size: 17px;
  }
`
const Title = styled.div<any>`
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: center;
  color: ${({ title_color }) => (title_color ? title_color : "#3c70cd")};
  @media only screen and (max-width: 950px) {
    margin-top: -10px;
    font-size: 18px;
  }
`

const BorderCard: React.FC<Props> = ({
  type,
  amount,
  title,
  headline,
  children,
  ...props
}) => {
  const textFieldUI = () => {
    switch (type) {
      case "amount-number":
        return (
          <Wrapper>
            <Container {...props} className="restricted">
              <div>
                <span className="amount">{amount}</span>{" "}
                <sub className="price">円</sub>
              </div>
            </Container>
            <Heading {...props}>
              <span>{headline}</span>
            </Heading>
          </Wrapper>
        )
      case "amount-text":
        return (
          <Wrapper>
            <Content {...props} className="restricted">
              {amount}
            </Content>
            <Heading {...props}>
              <span>{headline}</span>
            </Heading>
          </Wrapper>
        )
      default:
        return (
          <Wrapper>
            <Heading {...props}>
              <span>{headline}</span>
            </Heading>
            <Container {...props}>
              <ContainerContent>
                <Title {...props}>{title}</Title>
                <p className="children">{children}</p>
              </ContainerContent>
            </Container>
          </Wrapper>
        )
    }
  }

  return textFieldUI()
}
export default BorderCard
