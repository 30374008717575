import React from "react"
import styled from "styled-components"
import { theme } from "@project/shared"
import { Button } from "../../atoms"
import useScreenWidth from "../../../utils/useScreenWidth"

const RequestBannerContainer = styled.div`
  display: flex;
  background-image: url("/assets/images/dotted.png"),
    linear-gradient(90deg, #2b57a5 0%, #3c70cd 98.48%);
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1054px) {
    align-items: center;
    flex-direction: column-reverse;
  }
  .main-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 25px;
    padding-inline: 40px;
    @media (max-width: 1054px) {
      align-items: center;
    }
    @media (max-width: 768px) {
      padding-inline: 10px;
    }
    .text-style {
      font-weight: 700;
      font-size: 36px;
      line-height: 84px;
      letter-spacing: 0.04em;
      color: ${theme.base};
      @media (max-width: 500px) {
        margin: 20px 0;
        text-align: center;
        font-size: 25px;
        line-height: 45px;
      }
    }
    .normal-text-style {
      font-weight: 400;
      font-size: 16px;
      color: ${theme.base};
      margin-bottom: 4em;
      @media (max-width: 1054px) {
        white-space: break-spaces;
      }
    }
  }
  .request-banner {
    width: 50vw;
    @media (max-width: 1054px) {
      width: 100vw;
    }
  }
`

const RequestBanner = () => {
  const widthSize = useScreenWidth()
  return (
    <RequestBannerContainer>
      <div className="main-group">
        <span className="text-style">{"まずはお気軽に資料請求を！"}</span>
        <span className="normal-text-style">
          {
            "当社はネットの求人には載せないような情報もヒアリングさせていただきます。\nまずはお気軽に貴社採用要件について伺わせてください。当社の人材紹介\n企業の集客層で貢献できるか\nお話いたします。"
          }
        </span>
        <Button
          onClick={() => {
            const section = document.getElementById("download-documents")
            if (section) {
              section.scrollIntoView({ behavior: "smooth" })
            }
          }}
          showIcon={true}
          title="資料ダウンロード"
          backgroundColor="linear-gradient(90deg, #23385d 0%, #2b57a5 100%) !important;"
        />
      </div>
      <img
        src={
          widthSize <= 1054
            ? "/assets/images/request-banner-two.png"
            : "/assets/images/request-banner.png"
        }
        className="request-banner"
      />
    </RequestBannerContainer>
  )
}

export { RequestBanner }
