import React from "react"
import styled from "styled-components"
import { Button } from "@project/shared"
import { useTranslation } from "react-i18next"
import Router from "next/router"

const Container = styled.footer`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px 40px;

  & .footer-links {
    display: flex;
    margin: 10px;
  }

  & .footer-link {
    padding: 0 10px;
  }

  & .copyright {
    text-align: center;
  }

  @media (max-width: 768px) {
    & .footer-links {
      flex-direction: column;
    }
    & .footer-link {
      margin: 10px;
    }
  }
`

const handleLogin = () => {
  window.open(`${process.env.NEXT_PUBLIC_AGENT_HOST}/login`, "_blank")
}

const handlePrivacyPolicy = () => {
  Router.push("/privacy-policy")
}

const handleTermsAndConditions = () => {
  Router.push("/terms-and-conditions")
}

const Footer: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <div className="footer-links">
        <div className="footer-link">
          <Button width={`13rem`} borderradius={"20px"} onClick={handleLogin}>
            {t("Login")}
          </Button>
        </div>
        <div className="footer-link">
          <Button
            width={`13rem`}
            borderradius={"20px"}
            onClick={handlePrivacyPolicy}
          >
            {t("Privacy policy")}
          </Button>
        </div>
        <div className="footer-link">
          <Button
            width={`13rem`}
            borderradius={"20px"}
            onClick={handleTermsAndConditions}
          >
            {t("Terms and Conditions")}
          </Button>
        </div>
      </div>
      <div className="copyright">
        Copyright &copy; 2022 Sales agent | Powered by HR technologies
      </div>
    </Container>
  )
}

export { Footer }
