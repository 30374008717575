import styled from "styled-components"
import { InformationCard } from "../../../molecules"

const Wrapper = styled.div`
  position: relative;
  background-color: #e5e5e5;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .mask-img-first {
    width: 100%;
    @media (max-width: 900px) {
      height: 400px;
      object-fit: fill;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
  .mask-img-second {
    display: none;
    @media (max-width: 600px) {
      display: block;
      width: 100%;
    }
  }
  .main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 2em;
    margin-top: 76px;
    justify-content: center;
    overflow: hidden;
    .first-section {
      position: relative;
      display: flex;
      flex-direction: row;
    }
    .inner-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 150px;
    }

    .line-one,
    .line-two {
      position: absolute;
      height: 223px;
      width: 500px;
      right: 0;
      bottom: 0;
      top: 170px;
      @media (max-width: 2500px) {
        top: 170px;
      }
      @media (max-width: 2100px) {
        top: 190px;
      }
      @media (max-width: 1800px) {
        top: 150px;
      }
      @media (max-width: 900px) {
        top: 150px;
      }
      @media (max-width: 768px) {
        top: 180px;
      }
      @media (max-width: 695px) {
        top: 200px;
      }
      @media (max-width: 600px) {
        top: 250px;
        height: 200px;
        width: 450px;
      }
      @media (max-width: 500px) {
        height: 150px;
        width: 350px;
      }
    }
    .line-one {
      transform: rotate(40deg);
      left: 250px;
      @media (max-width: 2100px) {
        transform: rotate(40deg);
        left: 150px;
      }
      @media (max-width: 1800px) {
        transform: rotate(50deg);
        left: 120px;
      }
      @media (max-width: 1200px) {
        transform: rotate(55deg);
        left: 80px;
      }
      @media (max-width: 900px) {
        transform: rotate(60deg);
        left: 80px;
      }
      @media (max-width: 768px) {
        transform: rotate(63deg);
        left: 50px;
      }
      @media (max-width: 660px) {
        transform: rotate(70deg);
        left: 15px;
      }
      @media (max-width: 470px) {
        left: 20px;
      }
      @media (max-width: 450px) {
        left: 10px;
      }
      @media (max-width: 400px) {
        left: 0px;
      }
    }
    .line-two {
      transform: rotate(140deg);
      right: 250px;
      @media (max-width: 2100px) {
        transform: rotate(140deg);
        right: 150px;
      }
      @media (max-width: 1800px) {
        transform: rotate(130deg);
        right: 120px;
      }
      @media (max-width: 1200px) {
        transform: rotate(125deg);
        right: 80px;
      }
      @media (max-width: 900px) {
        transform: rotate(120deg);
        right: 80px;
      }
      @media (max-width: 768px) {
        transform: rotate(117deg);
        right: 50px;
      }
      @media (max-width: 660px) {
        transform: rotate(110deg);
        right: 15px;
      }
      @media (max-width: 450px) {
        right: 10px;
      }
      @media (max-width: 400px) {
        right: 0px;
      }
    }
    .line-three {
      width: 30%;
      height: 223px;
      min-width: 17px;
      @media (max-width: 768px) {
        height: 5px;
        object-fit: cover;
        width: 100%;
      }
    }
  }
  .sale-agent-logo {
    position: absolute;
    height: 60px;
    display: block;
    @media (max-width: 768px) {
      width: 170px;
      height: 70px;
    }

    @media (max-width: 550px) {
      width: 150px;
      height: 50px;
    }
    @media (max-width: 400px) {
      width: 120px;
      height: 30px;
    }
  }
  .features-logo {
    width: 100%;
    margin-top: -1em;
    padding-bottom: 5em;
    @media (max-width: 500px) {
      margin-top: -0.5em;
    }
  }
`

const BorderText = styled.span`
  width: 440px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  font-weight: 700;
  font-size: 36px;
  letter-spacing: 0.04em;
  color: #ffffff;

  @media (max-width: 1300px) {
    font-size: 24px;
    width: 300px;
    height: 80px;
  }
  @media (max-width: 900px) {
    font-size: 20px;
    height: 60px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
    height: 50px;
  }
  @media (max-width: 375px) {
    width: 250px;
  }
`
const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0 0 1em 0;
  @media (max-width: 900px) {
    font-size: 22px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`
const SubTitle = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin-top: 2rem;
  @media (max-width: 1300px) {
    font-size: 24px;
  }
  @media (max-width: 900px) {
    font-size: 22px;
    margin-top: 26px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`
const CrossText = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 72px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0 60px;
  @media (max-width: 1300px) {
    font-size: 24px;
    margin: 0 40px;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    margin: 0 30px;
  }
  @media (max-width: 500px) {
    line-height: 40px;
  }
`
const BannerContent = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 10%;
  padding-inline: 10px;
  @media (max-width: 1024px) {
    margin-top: 5%;
  }
  @media (max-width: 600px) {
    margin-top: 15%;
  }
`
const BannerButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const GrowthSection: React.FC = () => {
  return (
    <Wrapper>
      <img className={"mask-img-first"} src="/assets/images/group-bg.png" />
      <img
        className={"mask-img-second"}
        src="/assets/images/group-bg-second.svg"
      />
      <BannerContent>
        <Title>{"群雄割拠の人材業界で今後も成長するためには"}</Title>
        <BannerButtons>
          <BorderText>{"魅力的な求人企業数"}</BorderText>
          <CrossText>{"X"}</CrossText>
          <BorderText>{"求職者グリップ力の向上"}</BorderText>
        </BannerButtons>
        <SubTitle>{"が不可欠！"}</SubTitle>
      </BannerContent>
      <div className="main-wrapper">
        <div className="first-section">
          <img className="line-one" src="/assets/images/line-3.png" />
          <InformationCard
            title={"立ち上げ1ヶ月で300社達成"}
            description={"非公開求人も多数！\n圧倒的な\n求人数 x 採用人数"}
            titleHeight="48px"
            titleFontSize="16px"
            bgColor="#FFFFFF"
            descriptionFontSize="24px"
            responsive={true}
          />
          <img className="line-two" src="/assets/images/line-3.png" />
        </div>
        <img
          className="sale-agent-logo"
          src="/assets/images/sale_agent_logo.png"
        />
        <div className="inner-section">
          <InformationCard
            title={"弊社CSによる伴走型支援"}
            description={"月次目標設定から\nエージェントとしてのコツまで"}
            titleHeight="48px"
            titleFontSize="16px"
            bgColor="#FFFFFF"
            descriptionFontSize="24px"
            responsive={true}
          />
          <img className="line-three" src="/assets/images/line-3.png" />
          <InformationCard
            title={"職種特化によるきめ細やかな連携"}
            description={"一人一人の候補者にあった\n求人をピックアップ"}
            titleHeight="48px"
            titleFontSize="16px"
            bgColor="#FFFFFF"
            descriptionFontSize="24px"
            responsive={true}
          />
        </div>
      </div>
      <img className="features-logo" src="/assets/images/features.png" />
    </Wrapper>
  )
}

export { GrowthSection }
