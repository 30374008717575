import { theme } from "@project/shared"
import React from "react"
import styled from "styled-components"
import useScreenWidth from "../../../utils/useScreenWidth"

const CardStyle = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ coloumReverse }) =>
    coloumReverse ? "row-reverse" : "row"};
  position: relative;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: start;
  }
  margin: 1em 0;
  .first-image {
    width: 50%;
    margin: 30px auto 0 auto;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  .second-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-inline: 5em;
    @media (max-width: 1024px) {
      padding-inline: 1em;
      flex-direction: column;
    }
    .colored-text {
      color: ${theme.blueColouredText};
      font-weight: 700;
      font-size: 28px;
      padding-bottom: 1em;
      @media (max-width: 1024px) {
        font-size: 24px;
      }
      @media (max-width: 400px) {
        font-size: 22px;
      }
    }
    .normal-text {
      font-weight: 400;
      font-size: 18px;
      @media (max-width: 1024px) {
        white-space: break-spaces;
        font-size: 20px;
      }
      @media (max-width: 400px) {
        font-size: 18px;
      }
    }
  }
  .reason,
  .reason-reverse {
    position: absolute;
    height: 380px;
    z-index: -1;
    margin: 0 auto;
    @media (max-width: 1024px) {
      position: relative;
      width: 90vw;
      height: 100px;
      object-fit: contain;
      left: 0;
      right: 0;
    }
  }
  .reason {
    right: 3em;
    @media (max-width: 1024px) {
      right: 0;
    }
  }
  .reason-reverse {
    left: 3em;
    @media (max-width: 1024px) {
      left: 0;
    }
  }
`

const AgentReasonCard: React.FC<{
  reasonDetails: any
}> = ({ reasonDetails }) => {
  const screenSize = useScreenWidth()
  return (
    <CardStyle coloumReverse={reasonDetails?.coloumReverse}>
      <img src={reasonDetails?.image} className="first-image" />
      <img
        src={
          screenSize <= 1024
            ? reasonDetails?.reasonImageStright
            : reasonDetails?.reasonImage
        }
        className={`${
          !reasonDetails?.coloumReverse ? "reason" : "reason-reverse"
        }`}
      />
      <div className="second-group">
        <span className="colored-text">{reasonDetails?.title}</span>
        <p className="normal-text">{reasonDetails?.detail}</p>
      </div>
    </CardStyle>
  )
}

export { AgentReasonCard }
