import { theme } from "@project/shared"
import styled from "styled-components"

export interface IFunctionalCardProps {
  title: string
  description: string
}

const CardWrapper = styled.div<any>`
  width: 400px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 40px;
  flex-direction: column;
  background-color: ${theme.cardGreyBg};
  white-space: break-spaces;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 2em;
    padding: 10px;
  }
`
const Title = styled.div`
  margin: 0 auto;
  font-size: 22px;
  font-weight: 700;
  color: #2b57a5;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`
const HorizontalLine = styled.hr`
  border: none;
  border-top: 2px dotted #2b57a5;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
`

const Description = styled.div<any>`
  margin-top: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  @media (max-width: 768px) {
    font-size: 18px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
  }
`

export const FunctionalCard: React.FC<IFunctionalCardProps> = ({
  title,
  description,
}) => {
  return (
    <>
      <CardWrapper>
        <Title>{title}</Title>
        <HorizontalLine />
        <Description>{description}</Description>
      </CardWrapper>
    </>
  )
}
