import { useState, useEffect } from "react"

const useScreenWidth = () => {
  const [screenSize, setScreenSize] = useState(null)
  const isWindow = typeof window !== "undefined"
  const getWidth = () =>
    typeof window !== "undefined" ? window.screen.width : null
  const resize = () => setScreenSize(getWidth())

  useEffect(() => {
    if (isWindow) {
      setScreenSize(getWidth())
      window?.addEventListener("resize", resize)
      return () => window?.removeEventListener("resize", resize)
    }
  }, [isWindow])

  return screenSize
}

export default useScreenWidth
