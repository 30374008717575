import { theme } from "@project/shared"
import { Button, SectionHeader, TextField } from "../../../atoms"
import { Checkbox, notification } from "antd"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import * as yup from "yup"
import { useState } from "react"
import { useMutation } from "react-query"
import { postDocument } from "../../../../services"
import { useRouter } from "next/router"

interface Props {
  bg_color?: string
  content_bg_color?: string
}
const MainWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
  margin: auto;
  padding-bottom: 100px;
  background: ${({ bg_color }) => (bg_color ? bg_color : theme.base)};
  .ant-input {
    background-color: ${({ bg_color }) => (bg_color ? theme.base : bg_color)};
  }
  .ant-select-single .ant-select-selector {
    background-color: ${({ bg_color }) => (bg_color ? theme.base : bg_color)};
  }
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
    .ant-checkbox + span {
      margin-top: 5px;
    }
  }
  .show-error {
    .ant-checkbox-inner {
      border: 1px solid red !important;
    }
  }
`
const Wrapper = styled.div<any>`
  display: flex;
  justify-content: center;
  gap: 65px;
  flex-wrap: wrap;
  padding-inline: 10px;
  @media (max-width: 768px) {
    gap: 40px;
  }
`
const Container = styled.div`
  margin-top: 100px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
`
const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  margin-top: 50px;
  text-align: center;
  margin-left: 25px;
  margin-right: 25px;
  text-underline-offset: 5px;
  @media (max-width: 768px) {
    white-space: break-spaces;
  }
`
const Content = styled.div<any>`
  display: flex;
  justify-content: center;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-top: 50px;
    background: ${({ content_bg_color }) =>
      content_bg_color ? content_bg_color : theme.cardGreyBg};
    white-space: pre-wrap;
    width: 1024px;
    padding: 15px 30px;
    @media (max-width: 1030px) {
      width: 800px;
    }
    @media (max-width: 825px) {
      width: 600px;
    }
    @media (max-width: 625px) {
      width: 400px;
    }
  }
`
const AgreementContent = styled.div`
  margin-top: 25px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
`
const ButtonContainer = styled.div`
  margin-top: 80px;
`

const Document: React.FC<Props> = ({ ...props }) => {
  const [showError, setShowError] = useState(false)
  const router = useRouter()
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("Please enter valid email address"))
      .required(t("Please enter email address")),
    company_name: yup.string().required(t("Please enter company name")),
    department_name: yup.string().required(t("Please enter department name")),
    company_type: yup.string().required(t("Please select company type")),
    phone: yup.string().required(t("Please enter phone number")),
    pic_name: yup.string().required(t("Please enter pic name")),
  })
  const initialValues = {
    isArgreementChecked: false,
    company_name: "",
    department_name: "",
    pic_name: "",
    phone: "",
    email: "",
    company_type: "",
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (values.isArgreementChecked) {
        delete values["isArgreementChecked"]
        mutate(values)
        setShowError(false)
      } else {
        setShowError(true)
      }
    },
  })

  const { mutate, isLoading } = useMutation(postDocument, {
    onSuccess: () => {
      localStorage.setItem("direct-access", router.pathname)
      router.push(`thanks?from=${router.pathname.replace("/", "")}`)
      formik.resetForm()
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("An error has occurred. Please try again later."),
      })
    },
  })

  return (
    <MainWrapper {...props} id={"download-documents"}>
      <Container>
        <SectionHeader icon="contact-us" title="資料ダウンロード" />
      </Container>
      <Wrapper>
        <TextField
          name="company_name"
          value={formik.values.company_name}
          onChange={formik.handleChange}
          label="会社名"
          error={formik.touched.company_name && formik.errors?.company_name}
          required
        />
        <TextField
          name="department_name"
          value={formik.values.department_name}
          onChange={formik.handleChange}
          label="部署名"
          error={
            formik.touched.department_name && formik.errors?.department_name
          }
          required
        />
        <TextField
          name="pic_name"
          value={formik.values.pic_name}
          onChange={formik.handleChange}
          label="担当者名"
          error={formik.touched.pic_name && formik.errors?.pic_name}
          required
        />
        <TextField
          name="phone"
          value={formik.values.phone}
          onChange={(e: { target: { value: string } }) => {
            const value = e.target.value
            if (/^[0-9]*\.?[0-9]*$/.test(value) && value?.length <= 15) {
              formik.setFieldValue("phone", value)
            }
          }}
          label="電話番号"
          error={formik.touched.phone && formik.errors?.phone}
          required
        />

        <TextField
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          label="メールアドレス"
          error={formik.touched.email && formik.errors?.email}
          required
        />
        <TextField
          name="company_type"
          value={formik.values.company_type}
          onChange={(value) => formik.setFieldValue("company_type", value)}
          label="該当企業種別"
          error={formik.touched.company_type && formik.errors?.company_type}
          required
          type="select"
          options={[
            {
              value: t("Recruiting Companies"),
              label: t("Recruiting Companies"),
            },
            {
              value: t("Introducing Companies"),
              label: t("Introducing Companies"),
            },
            { value: t("Other"), label: t("Other") },
          ]}
        />
      </Wrapper>{" "}
      <Text>
        {
          "下記の「利用規約・個人情報の取り扱いに\nついて」をお読みの上、資料ダウンロードへ\nお進みください。"
        }
      </Text>
      {false && (
        <>
          <Content {...props}>
            <span>
              {` ———————\n 個人情報の取り扱いについて \n———————\n株式会社○○○○(以下、「当社」といいます。)は、本ウェブサイト上で提供するサービス(以下、「本サービス」といいます。)におけるユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー(以下、「本ポリシー」といいます。)を定めます。
         \n■第1条(個人情報)`}
            </span>
          </Content>
        </>
      )}
      <a href="https://sales-agent.co.jp/privacy-policy" target="_blank">
        <Text style={{ textDecoration: "underline" }}>
          {"個人情報の取り扱いについて"}*
        </Text>
      </a>
      <AgreementContent>
        <Checkbox
          checked={formik.values.isArgreementChecked}
          onChange={(e) => {
            formik.setFieldValue("isArgreementChecked", e.target.checked)
          }}
          className={
            !formik.values.isArgreementChecked && showError && "show-error"
          }
        >
          {"同意する"}
        </Checkbox>
      </AgreementContent>
      <ButtonContainer>
        <Button
          showIcon
          title="資料ダウンロード"
          loading={isLoading}
          onClick={() => {
            formik.handleSubmit()
          }}
        />
      </ButtonContainer>
    </MainWrapper>
  )
}
export { Document }
