import { theme } from "@project/shared"
import React from "react"
import styled from "styled-components"
import { SectionHeader } from "../../atoms"

const TableContainer = styled.div`
  padding-top: 20px;
  @media (max-width: 768px) {
    padding-top: 0;
  }
  .section-header {
    margin: 4em 0;
    @media (max-width: 768px) {
      margin: 0;
    }
  }
  .table-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-x: scroll;

    table {
      border-collapse: separate;
      border-spacing: 1em;
      align-self: center;
      max-width: 1500px;
      min-width: 375px;

      @media (max-width: 1080px) {
        align-self: start;
      }
      .image-icon {
        height: 70px;
        width: 100%;
        min-width: 54.49px;
        min-height: 12.24px;
        padding-inline: 10px;
      }
      th {
        font-size: 30px !important;
        @media (max-width: 768px) {
          font-size: 16px !important;
        }
        @media (max-width: 425px) {
          font-size: 14px !important;
        }
      }
      th,
      td {
        color: ${theme.base};
        font-weight: 400;
        font-size: 16px;
        height: 112.74px;
        width: 307.48px;
        min-width: 70.53px;
        min-height: 29.53px;
        @media (max-width: 768px) {
          font-size: 14px;
        }
        @media (max-width: 425px) {
          font-size: 12px;
        }
      }
      td,
      th:not(:nth-child(1)) {
        vertical-align: middle;
        text-align: center;
      }
      th:nth-child(2) {
        background-color: ${theme.darkBlue};
      }
      th:nth-child(3),
      th:nth-child(4) {
        background-color: ${theme.skyBlue};
      }
      td:nth-child(1),
      td:nth-child(3),
      td:nth-child(4) {
        color: ${theme.black};
      }
      td:nth-child(1) {
        background-color: ${theme.smokeyWhite};
      }
      tr:nth-child(1) td:nth-child(n + 2):nth-child(-n + 4),
      tr:nth-child(3) td:nth-child(n + 2):nth-child(-n + 4),
      tr:nth-child(5) td:nth-child(n + 2):nth-child(-n + 4) {
        background-color: ${theme.fadedWhite};
      }
      tr:nth-child(2) td:nth-child(n + 2):nth-child(-n + 4),
      tr:nth-child(4) td:nth-child(n + 2):nth-child(-n + 4),
      tr:nth-child(6) td:nth-child(n + 2):nth-child(-n + 4) {
        background-color: ${theme.lightBlueInTable};
      }
    }
  }
`

const Table = () => {
  return (
    <TableContainer id="comparison">
      <SectionHeader
        title={"他社比較"}
        icon="personal-costs"
        className="section-header"
      />
      <div className="table-wrapper">
        <table>
          <tr>
            <th />
            <th>
              <img className="image-icon" src="/assets/icons/logo-white.svg" />
            </th>
            <th>{"競合A"}</th>
            <th>{"競合B"}</th>
          </tr>

          <tr>
            <td>{"求職者管理"}</td>
            <td>
              <img
                src={"/assets/icons/circle-icon.png"}
                width={15}
                height={15}
              />
            </td>
            <td>{"△"}</td>
            <td>{"○"}</td>
          </tr>

          <tr>
            <td>{"求人管理"}</td>
            <td>
              <img
                src={"/assets/icons/circle-icon.png"}
                width={15}
                height={15}
              />
            </td>
            <td>{"○"}</td>
            <td>{"○"}</td>
          </tr>

          <tr>
            <td>{"サポート"}</td>
            <td>
              <img
                src={"/assets/icons/circle-icon.png"}
                width={15}
                height={15}
              />
            </td>
            <td>{"×"}</td>
            <td>{"×"}</td>
          </tr>

          <tr>
            <td>{"料金"}</td>
            <td>
              <img
                src={"/assets/icons/circle-icon.png"}
                width={15}
                height={15}
              />
            </td>
            <td>{"△"}</td>
            <td>{"△"}</td>
          </tr>
          <tr>
            <td>{"求人管理"}</td>
            <td>
              <img
                src={"/assets/icons/circle-icon.png"}
                width={15}
                height={15}
              />
            </td>
            <td>{"○"}</td>
            <td>{"△"}</td>
          </tr>
        </table>
      </div>
    </TableContainer>
  )
}

export { Table }
