import React from "react"
import styled from "styled-components"
import { iServiceItem } from "../../../../interface/serviceItem"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  @media (min-width: 900px) {
    margin-top: 80px;
    flex-direction: row;
    justify-content: flex-start;
    & > :first-child {
      margin-right: 50px;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      & > :first-child {
        margin-right: 0px;
        margin-left: 50px;
      }
    }
  }
  @media (min-width: 1200px) {
    & > :first-child {
      margin-right: 80px;
    }
    &:nth-child(even) {
      & > :first-child {
        margin-left: 80px;
      }
    }
  }
`
const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
  @media (min-width: 478px) {
    img {
      width: 350px;
      height: 350px;
    }
  }
`
const ServiceItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  button {
    height: 43px;
    width: 250px;
  }
  @media (min-width: 900px) {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
  }
`
const Number = styled.div`
  font-size: 3em;
  color: #000f231a;
  font-weight: 700;
  margin-bottom: 20px;
  @media (min-width: 478px) {
    font-size: 5em;
  }
  @media (min-width: 786px) {
    margin-bottom: 25px;
  }
`
const Title = styled.div`
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  margin-bottom: 20px;
  @media (min-width: 478px) {
    font-size: 1.67rem;
    line-height: 35px;
  }
`
const Content = styled.div`
  font-size: 14px;
  line-height: 25.5px;
  color: #5d7280;
  white-space: break-spaces;
  margin-bottom: 50px;
  @media (min-width: 478px) {
    font-size: 15px;
    line-height: 28px;
  }
`

const ServiceItem: React.FC<iServiceItem> = ({
  imgSrc,
  title,
  content,
  number,
}) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={imgSrc} height={350} width={350} alt={title} />
      </ImageWrapper>
      <ServiceItemContent>
        <Number>#{number}</Number>
        <Title>{title}</Title>
        <Content>{content}</Content>
      </ServiceItemContent>
    </Wrapper>
  )
}

export { ServiceItem }
