import styled from "styled-components"
import { ButtonComponent as Button } from "../Buttons"
import { Drawer, Menu } from "antd"
import React, { useState } from "react"
import { useRouter } from "next/router"
import useScreenWidth from "../../../utils/useScreenWidth"
import { theme } from "@project/shared"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  padding: 15px 30px;
  .header-logo {
    width: 240px;
    height: 54.38px;
    cursor: pointer;
  }
  @media (max-width: 376px) {
    .header-logo {
      width: 220px;
      height: 44.38px;
    }
    padding: 15px 10px;
  }
  .buttons-in-header {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 16px;
    p {
      margin-bottom: 0;
      margin-right: 20px;
    }
    .button-style {
      padding: 0 20px;
      span {
        font-size: 16px;
      }
      .button-icon {
        height: 30px;
      }
    }
  }
  .drawer-icon {
    width: 40px;
    height: 40px;
  }
`

const DrawerWrapper = styled(Drawer)`
  .ant-menu {
    border: none !important;
    background-color: ${theme.sideNavColor};
  }
  .ant-drawer-header {
    display: flex;
    justify-content: end;
    padding: 10px 0;
  }
  .ant-drawer-body {
    padding-top: 0;
    padding-right: 3em;
    display: flex;
    background-color: ${theme.sideNavColor};
  }
`

const MobileMenu = styled(Menu)`
  .ant-menu-title-content {
    font-weight: 700;
    font-size: 16px;
    text-align: end;
  }
  .ant-menu-item {
    display: flex;
    margin: 35px -10px;
    justify-content: start;
  }
`
const NewHeader = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false)
  const router = useRouter()
  const screenSize = useScreenWidth()

  const isThankYouPage = router.asPath.includes("thanks")
  // const isAgentPage = router.asPath.includes("agents")
  const isAgentPage = true

  const { from } = router?.query

  const menuItem = isAgentPage
    ? [
        { label: "Sales Agentとは?", route: "connect-companies" },
        { label: "紹介先例", route: "sales-agents" },
        { label: "機能紹介", route: "function-introduction" },
        { label: "他社との比較", route: "comparison" },
      ]
    : [
        { label: "Sales Agentとは?", route: "connect-companies" },
        { label: "利用料金", route: "performance-reward" },
        { label: "選ばれる理由", route: "sales-agents" },
        { label: "機能紹介", route: "function-introduction" },
      ]
  // company

  const gotoSection = (sectionID) => {
    const section = document.getElementById(sectionID)
    if (section) {
      section.scrollIntoView({ behavior: "smooth" })
    }
  }

  if (screenSize < 1024) {
    return (
      <Wrapper>
        <img
          className={"header-logo"}
          src={"/assets/icons/logo.gif"}
          onClick={() => from && router.push(`/${from}`)}
        />
        {!isOpenDrawer ? (
          <img
            className="drawer-icon"
            src={"/assets/icons/burger-icon.svg"}
            onClick={() => setIsOpenDrawer(true)}
          />
        ) : (
          <img
            className="drawer-icon"
            src={"/assets/icons/cross-icon.svg"}
            onClick={() => setIsOpenDrawer(false)}
          />
        )}
        {isOpenDrawer && (
          <DrawerWrapper
            placement={"right"}
            onClose={() => setIsOpenDrawer(false)}
            visible={true}
            closeIcon={
              <div style={{ display: "flex" }}>
                <img
                  src={"/assets/icons/logo.gif"}
                  className="header-logo"
                  onClick={() => from && router.push(`/${from}`)}
                  width={150}
                  height={42}
                  style={{ marginRight: 20, position: "absolute", left: 20 }}
                />
                <img
                  className="drawer-icon"
                  src={"/assets/icons/cross-icon.svg"}
                  onClick={() => setIsOpenDrawer(false)}
                />
              </div>
            }
            width={screenSize < 500 ? "70%" : "50%"}
          >
            <MobileMenu
              mode={"vertical"}
              defaultSelectedKeys={["agents"]}
              onClick={() => setIsOpenDrawer(false)}
            >
              {menuItem?.map((item, index) => (
                <Menu.Item
                  key={index}
                  onClick={() => {
                    router.pathname.includes("/thanks")
                      ? router.push(`/${from}#${item.route}`)
                      : gotoSection(item?.route)
                  }}
                >
                  {item?.label}
                </Menu.Item>
              ))}
            </MobileMenu>
          </DrawerWrapper>
        )}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <img
        src={"/assets/icons/logo.gif"}
        className="header-logo"
        onClick={() => from && router.push(`/${from}`)}
      />
      <div className="buttons-in-header">
        {menuItem?.map((item, index) => (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              router.pathname.includes("/thanks")
                ? router.push(`/${from}#${item.route}`)
                : gotoSection(item?.route)
            }}
            key={index}
          >
            {item?.label}
          </p>
        ))}
        {!isThankYouPage && (
          <Button
            showIcon={true}
            title="資料ダウンロード"
            className="button-style"
            width="230px"
            height="46px"
            onClick={() => gotoSection("download-documents")}
          />
        )}
      </div>
    </Wrapper>
  )
}
export { NewHeader }
