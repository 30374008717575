import styled from "styled-components"
import IntroductionCard from "../IntroductionCard"
import { SectionHeader } from "../../../atoms"

const Wrapper = styled.div`
  padding: 40px;
  max-width: 1800px;
  margin: 0 auto;
  display: grid;
  @media (max-width: 768px) {
    padding-inline: 20px;
  }
`
const Title = styled.div`
  color: #23385d;
  font-size: 24px;
  font-weight: 700;
  margin-top: 3em;
  @media (max-width: 768px) {
    text-align: center;
  }
`
const Content = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 1054px) {
    flex-direction: column;
    align-items: center;
    padding-inline: 0;
    gap: 100px;
  }
  @media (max-width: 768px) {
    gap: 50px;
  }
`

const FunctionIntroduction = () => {
  return (
    <Wrapper id="function-introduction">
      <SectionHeader icon={"function"} title="機能紹介" />
      <Title>求職者管理機能</Title>
      <Content>
        <IntroductionCard
          icon="/assets/icons/card1.svg"
          title="ボタン一つで企業エントリー"
          content={
            "一度求職者を登録すると、求人への\n応募の際はボタン1つで応募が可能\nです。複数エントリーでの各ATSへの\n連携の必要がなく生産性があがります。"
          }
          number={"01"}
        />
        <IntroductionCard
          icon="/assets/icons/card2.svg"
          title="人事からのメッセージ"
          content={`人事からエージェントに向けた「ぶっ\nちゃけた」メッセージを記載すること\nで求人の温度感や応募の際に気を\nつけることがわかりやすくなります。`}
          number={"02"}
        />
        <IntroductionCard
          icon="/assets/icons/card3.svg"
          title="チャット"
          content={`メッセージアプリを使用している感覚\nで求人企業様とダイレクトで連絡\nいただけますのでスピーディな連携が\n可能です。`}
          number={"03"}
        />
      </Content>
      <Content>
        <IntroductionCard
          icon="/assets/icons/card4.svg"
          title="求職者管理"
          content={
            "求職者の状況がひと目でわかります。\n権限設定によってチャット欄の閲覧制\n限をかけることができますので\nマネージャーは、メンバー全員の求職\n者を管理しやすくなります。"
          }
          number={"04"}
        />
        <IntroductionCard
          icon="/assets/icons/card5.svg"
          title="CSからのメッセージ"
          content={`求人票には人材会社側だけ閲覧できる\nCSからのメッセージ欄がございます。\nそれによって企業の温度感なども\nわかりますので自社求人\nのように使うことができます。`}
          number={"05"}
        />
        <IntroductionCard
          icon="/assets/icons/card6.svg"
          title="選考NG例の記載"
          content={`書類や面接でのNG事例を記載すること\nで確度読みがしやすくなります。`}
          number={"06"}
        />
      </Content>
      <Content>
        <IntroductionCard
          icon="/assets/icons/card7.svg"
          title="逆オファー機能"
          content={`求職者の応募対応すると、類似企業に\n属性情報のお知らせが届き、面談希望\nする場合は逆オファーの連絡が届きます。`}
          number={"07"}
        />
        <IntroductionCard
          icon="/assets/icons/card8.svg"
          title="目標設定支援"
          content={`年間での売上目標を設定して、月次で\nMTGを開催します。KPIを設定して\nその目標に向かって週毎に振り返り\nなども行います。`}
          number={"08"}
        />
        <IntroductionCard
          icon="/assets/icons/card9.svg"
          title="個別にカスタマイズした提案"
          content={`弊社CSにより求職者ごとに合う求人を\nセールスエージェント上からピック\nアップいたします。応募承諾をいただ\nけるように紹介のポイントや企業の\n魅力なども合わせてお伝えいたします。`}
          number={"09"}
        />
      </Content>
    </Wrapper>
  )
}
export { FunctionIntroduction }
