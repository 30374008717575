import React from "react"
import styled from "styled-components"
import { theme } from "@project/shared"
import { Button } from "../../atoms"

interface IBannerProps {
  agent?: boolean
}

const BannerContainer = styled.div<IBannerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1054px) {
    flex-direction: column-reverse;
  }
  .main-group {
    display: flex;
    flex-direction: column;
    padding-inline: 40px;
    justify-content: center;
    align-items: start;
    @media (max-width: 1054px) {
      padding-inline: 20px;
      justify-content: flex-start;
    }
    .first-text-group {
      margin-bottom: 25px;
      .blue-colored-text {
        font-weight: 700;
        font-size: 56px;
        line-height: 84px;
        letter-spacing: 0.04em;
        color: ${theme.blueColouredText};
        @media (max-width: 1300px) {
          font-size: 32px;
          line-height: 20px;
        }
        @media (max-width: 768px) {
          font-size: 27px;
          line-height: 20px;
        }
      }
      .normal-text {
        font-weight: 700;
        font-size: 36px;
        line-height: 84px;
        @media (max-width: 1300px) {
          font-size: 24px;
        }
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
    }
    .second-text-group {
      margin-bottom: 2em;
      @media (max-width: 1054px) {
        white-space: break-spaces;
      }
      .colored-text {
        color: ${theme.blueColouredText};
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.04em;
        line-height: 32px;
      }
      .normal-text {
        font-weight: 700;
        line-height: 32px;
        font-size: 18px;
      }
    }
    .ant-btn {
      margin-left: 0;
      @media (max-width: 1054px) {
        margin: 0 auto;
      }
    }
  }
  .banner {
    width: 50vw;
    @media (max-width: 1054px) {
      display: none;
    }
  }
  .banner-two {
    display: none;
    @media (max-width: 1054px) {
      display: block;
      width: 100vw;
    }
  }
`

const NewBannerSection: React.FC<IBannerProps> = ({ agent = true }) => {
  return (
    <BannerContainer>
      <div className="main-group">
        <div className="first-text-group">
          <span className="blue-colored-text">{"セールス職"}</span>
          <span className="normal-text">{"に特化した"}</span>
          <br />
          <span className="blue-colored-text">{"求人プラットフォーム"}</span>
        </div>
        <div className="second-text-group">
          {!agent ? (
            <>
              <span className="normal-text">
                {"ビジネス職に特化して求人を開拓して\n"}
              </span>
              <span className="colored-text">
                {"システムで一元管理"}
                <br />
                {"求職者対応の時間を確保"}
              </span>
              <span className="normal-text">と</span>
              <span className="colored-text">
                {"優良求人の\n紹介を両立する"}
              </span>
              <br />
              <span className="normal-text">
                {"人材紹介会社向けのRA業務のDX\nサービスです。"}
              </span>
            </>
          ) : (
            <>
              <span className="normal-text">
                {"ビジネス人材の紹介に特化した人材会社の\n求職者データを"}
              </span>
              <span className="colored-text">{"システム上で一元化。\n"}</span>
              <span className="normal-text">{"要望に合う営業人材の"}</span>
              <span className="colored-text">
                {"効率的採用を実現する\n人事DXサービス"}
              </span>
              <span className="normal-text">{"です。"}</span>
            </>
          )}
        </div>
        <Button
          showIcon={true}
          title="資料ダウンロード"
          onClick={() => {
            const section = document.getElementById("download-documents")
            if (section) {
              section.scrollIntoView({ behavior: "smooth" })
            }
          }}
        />
      </div>
      <div>
        <img src={"/assets/images/banner.png"} className="banner" />
        <img src={"/assets/images/banner-two.png"} className="banner-two" />
      </div>
    </BannerContainer>
  )
}

export { NewBannerSection }
