import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const SectionContainer = styled.div<any>`
  padding: 10px;
  .section-header {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 36px;
      text-align: center;
      margin-left: 10px;
      @media (max-width: 768px) {
        font-size: 28px;
      }
      @media (max-width: 500px) {
        font-size: 24px;
      }
    }
    .section-image {
      @media (max-width: 500px) {
        height: 40px;
        width: 40px;
      }
    }
  }
  @media (max-width: 478px) {
    .section-title-icon {
      height: 35px;
      width: 35px;
    }
  }
`

enum SectionType {
  SALES = "sales",
  FUNCTION = "function",
  PERSONAL_COSTS = "personal-costs",
  CONTACT_US = "contact-us",
}

type _IconType = "sales" | "function" | "personal-costs" | "contact-us"

interface ISectionHeaderProps {
  title?: string
  icon: _IconType
  className?: string
}

const getIcon = (icon: _IconType) => {
  switch (icon) {
    case SectionType.SALES:
      return (
        <img src={"/assets/icons/sales-agent.svg"} className="section-image" />
      )
    case SectionType.CONTACT_US:
      return <img src={"/assets/icons/mailbox.svg"} className="section-image" />
    case SectionType.PERSONAL_COSTS:
      return (
        <img src={"/assets/icons/calender.svg"} className="section-image" />
      )
    case SectionType.FUNCTION:
      return (
        <img src={"/assets/icons/functions.svg"} className="section-image" />
      )
    default:
      break
  }
}

const SectionHeader: React.FC<ISectionHeaderProps> = ({
  title,
  icon,
  className,
}) => {
  const { t } = useTranslation()
  return (
    <SectionContainer>
      <div className={`section-header ${className}`}>
        {getIcon(icon)}
        <span>{t(title)}</span>
      </div>
    </SectionContainer>
  )
}

export { SectionHeader }
