import { FunctionalCard } from "../../../molecules"
import styled from "styled-components"
import { SectionHeader } from "../../../atoms"

const Wrapper = styled.div`
  /* padding-top: 20px; */
  .icon {
    margin-top: 10%;
    margin-bottom: 50px;
    gap: 20px;
  }
`
const Container = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
`
const AgentFunction = () => {
  return (
    <Wrapper id={"function-introduction"}>
      <SectionHeader className="icon" title="機能紹介" icon="function" />
      <Container>
        <FunctionalCard
          title="求人掲載"
          description={
            "ビジネスサイド職であれば何個でも\n求人掲載が可能です。ボタンひとつで\n入れ替えや修正もできるので、反映が\nしやすいです。"
          }
        />
        <FunctionalCard
          title="ダッシュボードでの管理"
          description={
            "面接の選考段階ごとに求職者を一覧\n表示することが可能です。\n選考漏れを防ぐことができます。"
          }
        />
        <FunctionalCard
          title={"チャット機能"}
          description={
            "それぞれの紹介会社と直接連絡を取る\nことが可能です。これにより\nスピーディーな選考が実現します。"
          }
        />
        <FunctionalCard
          title={"採用手数料と返金の一括管理"}
          description={
            "採用手数料や返金規定などを一括で管理\nしながら、100社近くのエージェントから\n紹介を増やすことができます。これにより\n担当の増減や変更があっても連携がしやす\nくなります。"
          }
        />
        <FunctionalCard
          title={"情報の一元管理"}
          description={
            "エージェントからの推薦文や推薦書類\nはサービス上でいつでもダウンロード\nでき、閲覧できます。社内共有など\n複数で確認する際もURLを送って\nいただければスムーズな連携が可能\nです。"
          }
        />
      </Container>
    </Wrapper>
  )
}

export { AgentFunction }
