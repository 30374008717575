import styled from "styled-components"
import BorderCard from "../BorderCard"
import { theme } from "@project/shared"

const Wrapper = styled.div`
  max-width: 1500px;
  padding-top: 5em;
  text-align: center;
  margin: auto;
  @media (max-width: 768px) {
    padding-top: 2em;
  }
  .span-blue {
    color: #3c70cd;
    font-size: 36px;
    font-weight: 700;
    line-height: 84px;
    @media (max-width: 720px) {
      line-height: 40px;
    }
    .span-black {
      color: #000;
      @media (max-width: 720px) {
        white-space: pre-line;
      }
    }
  }
  @media only screen and (max-width: 720px) {
    .span-blue {
      font-size: 18px;
      margin-left: 20px;
    }
  }
  .performance-text {
    text-align: end;
    padding-inline: 20px;
    color: ${theme.fadedGrey};
    margin: 0 0 5em 0;
    @media (max-width: 720px) {
      text-align: start;
    }
  }
`
const Content = styled.div`
  display: flex;
  padding: 80px;
  justify-content: space-between;
  @media (max-width: 850px) {
    flex-direction: column;
    gap: 50px;
    padding: 30px;
    align-items: center;
  }
`
const PerformanceReward = () => {
  return (
    <Wrapper id={"performance-reward"}>
      <span className="span-blue">
        {"基本利用料0円"}
        <span className="span-black">{"で"}</span>
        {"完全成果報酬制"}
        <span className="span-black">{"\nだから安心。"}</span>
      </span>
      <Content>
        <BorderCard
          type="amount-number"
          amount={0}
          headline={"基本利用料"}
          border_color={"#3C70CD"}
        />
        <BorderCard
          type="amount-text"
          amount={"無制限"}
          headline={"求人掲載数"}
          border_color={"#3C70CD"}
        />
        <BorderCard
          type="amount-text"
          amount={"自由設定"}
          headline={"成果報酬金額"}
          border_color={"#3C70CD"}
        />
      </Content>
      {false && (
        <p className="performance-text">
          {
            "※備考が入ります備考が入ります備考が入ります備考が入ります備考が入ります備考が入ります"
          }
          <br />
          {
            "※備考が入ります備考が入ります備考が入ります備考が入ります備考が入ります備考が入ります"
          }
        </p>
      )}
    </Wrapper>
  )
}
export { PerformanceReward }
