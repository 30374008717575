import React from "react"
import styled from "styled-components"
import { Button } from "@project/shared"
import { Link as ScrollLink } from "react-scroll"

const BannerWrapper = styled.div`
  background-image: url("/assets/images/banner.jpeg");
  background-position: bottom center;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
  & .bg-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #000f23;
    opacity: 0.65;
    z-index: 1;
  }
  & .banner-container {
    z-index: 9999;
    max-width: 1480px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    & .content-wrapper {
      padding: 12% 0 10% 5%;
      width: 100%;
      margin: 0 auto;
      & .title {
        color: #d7a993;
        font-size: 18px;
        font-weight: bold;
      }
      & .desp {
        color: #fff;
        font-size: 37px;
        font-weight: bold;
        margin-top: 30px;
        & .text1,
        .text2 {
          & br {
            display: none;
          }
        }
      }
      & .divider {
        width: 50px;
        height: 3px;
        background: #e06126;
        margin-top: 30px;
      }
      & .btn-inquiry {
        margin-top: 50px;
        background: #e06126;
        height: 44px;
        width: 140px;
        border-radius: 30px;
      }
    }
  }
  @media (max-width: 980px) {
    & .content-wrapper {
      padding: 25% 0 19% 5% !important;
      text-align: center;
      padding-left: 0 !important;
      & .divider {
        margin-left: auto;
        margin-right: auto;
      }
      & .btn-inquiry {
        margin-top: 90px !important;
      }
      .text2 {
        & br {
          display: block !important;
        }
      }
    }
  }
  @media (max-width: 768px) {
    & .content-wrapper {
      .text1 {
        & br {
          display: block !important;
        }
      }
    }
  }
  @media (max-width: 520px) {
    & .content-wrapper {
      padding: 30% 3% 23% 3% !important;
    }
    & .title {
      font-size: 16px;
    }
    & .desp {
      font-size: 28px !important;
    }
  }
  @media (max-width: 400px) {
    & .content-wrapper {
      .text1,
      .text2 {
        font-size: 25px;
      }
    }
  }
  @media (max-width: 350px) {
    & .content-wrapper {
      .text1,
      .text2 {
        font-size: 22px;
      }
    }
  }
`

const BannerSection = () => {
  return (
    <BannerWrapper>
      <div className="bg-overlay" />
      <div className="banner-container">
        <div className="content-wrapper">
          <div className="title">人材紹介事業の成長を加速させる</div>
          <div className="desp">
            <div className="text1">
              国内最大級の
              <br />
              「営業求人データベース」
            </div>
            <div className="text2">
              を保有 セールス求人特化
              <br />
              の人材紹介プラットフォーム
            </div>
          </div>
          <div className="divider"></div>
          <Button className="btn-inquiry">
            <ScrollLink to="inquiry-section" smooth={true} duration={500}>
              お問い合わせ
            </ScrollLink>
          </Button>
        </div>
      </div>
    </BannerWrapper>
  )
}

export { BannerSection }
