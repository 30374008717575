import { theme } from "@project/shared/src/theme"
import styled from "styled-components"
import { IssuesCard } from "../../../molecules"

const Wrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 10em 0;
  @media (max-width: 786px) {
    padding: 80px 20px;
  }
  @media (max-width: 375px) {
    padding: 80px 20px;
  }
`
const Title = styled.div<any>`
  text-align: center;
  margin-bottom: 42px;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 72px;
  color: ${theme.blackTextColor};
  @media (max-width: 786px) {
    font-size: 25px;
    line-height: 50px;
    white-space: break-spaces;
  }
  @media (max-width: 400px) {
    font-size: 20px;
    line-height: 30px;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  gap: 50px;
`

const IssuesSection: React.FC = () => {
  return (
    <Wrapper>
      <Title>{"立ち上げたばかりの人\n材紹介会社の課題"}</Title>
      <Content>
        <IssuesCard
          title="事業の伸ばし方がわからない"
          description={
            "代表や事業責任者のみでメンバーを\n入れて拡大させるイメージが\nわかない。"
          }
        />
        <IssuesCard
          title="単価が低い"
          description={
            "決まりやすい求人は単価が低いので\n求職者の人数が足りない。"
          }
        />
        <IssuesCard
          title={"広く浅くの情報で\nグリップができない"}
          description={
            "求人数は多いがリアルな情報が\nわからず他社のエージェントに\n逃げられてしまう。"
          }
        />
      </Content>
    </Wrapper>
  )
}
export { IssuesSection }
