import { t } from "i18next"
import React from "react"
import styled from "styled-components"

interface iPrivacy {
  useTranslation?: boolean
}

const Wrapper = styled.div`
  & .title {
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 20px;
  }

  & .subtitle {
    font-size: 16px;
  }

  & .title.main-title {
    margin-bottom: 70px;
  }
  & .subtitle.main-subtitle {
    margin-bottom: 40px;
    font-size: 16px;
  }
  & .top-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }

  & .points {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 80px;

    & span {
      display: block;
    }
  }

  & .sub-topic {
    margin-bottom: 40px;
    & h2 {
      font-size: 24px;
      font-weight: 700;
    }
    & span,
    p {
      font-size: 16px;
    }
    & .contact-info {
      display: block;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
`

const noTranslate = (text) => text

const Privacy: React.FC<iPrivacy> = ({ useTranslation }) => {
  const translate = useTranslation ? t : noTranslate
  return (
    <Wrapper>
      <div className="top-text">
        <h2 className="title main-title">{translate("Privacy policy")}</h2>
      </div>
      <div className="points">
        <div className="subtitle main-subtitle">
          {translate(
            "「Sales agent」(Hereinafter referred to as 「this site」) establishes the following privacy policy regarding the handling of user’s personal information."
          )}
        </div>
        <div className="title">
          {translate("1. Definition of privacy policy")}
        </div>
        <div className="subtitle">
          {translate(
            "In this privacy policy, personal information refers to information about living individuals that falls under any of the following items. History of pages and advertisements viewed, search keywords searched by the user, date and time of use, method of use, usage environment, zip code and gender, occupation, age, user’s IP address, cookie information, location information ， Refers to the individual identification information of the terminal."
          )}
        </div>
      </div>
      <div className="points">
        <div className="title">
          {translate(
            " 2. Use of information obtained from API of external service"
          )}
        </div>
        <div className="subtitle main-subtitle">
          {translate(
            " With the permission of the user to integrate with their Google Calendar, this site will use the acquired information for the following purposes."
          )}
          <span>
            {translate(
              "⑴ To obtain user's google email address,username and sync google calendar informations."
            )}
          </span>
          <span>
            {translate(
              "⑵ To manage (add, edit, delete) calendars from this site"
            )}
          </span>
          <span>
            {translate(
              "*We are using following three scope of the Google Calendar API on this site."
            )}
          </span>
          <span>
            https://www.googleapis.com/auth/calendar
            <span>https://accounts.google.com/o/oauth2/</span>
            <span> https://www.googleapis.com/oauth2/</span>
          </span>
          <span>
            {translate(
              "* By using this site service you also agree to the following Google privacy policy and terms."
            )}
          </span>
          <span>
            <a href="https://policies.google.com/privacy" target="_blank">
              {translate("Google Privacy Policy")}
            </a>
          </span>
          <span>
            <a href="https://policies.google.com/terms" target="_blank">
              {translate("Google Terms and Conditions")}
            </a>
          </span>
        </div>
      </div>
      <div>
        <div className="points">
          <div className="title">
            {translate("3. Purpose of using personal information")}
          </div>
          <div className="subtitle">
            {translate(
              "This site use personal information for the following purposes."
            )}
            <span>
              {translate(
                "⑴ To respond to information and inquiries regarding this service"
              )}
            </span>
            <span>
              {translate("⑵ For information on our products and services")}
            </span>
            <span>
              {translate(
                "⑶ To respond to acts that violate our terms and policies regarding this service"
              )}
            </span>
            <span>
              {translate(
                "⑷ To notify changes to the terms and conditions of this service"
              )}
            </span>
            <span>
              {translate(
                "⑸ To help improve our services, develop new services, etc."
              )}
            </span>
            <span>
              {translate(
                "⑹ For shareholder management, corporate law and other legal procedures 「personal information of shareholders, stock acquisition rights holders, etc.」"
              )}
            </span>
            <span>
              {translate(
                "⑺ To create statistical data processed into a format that cannot identify individuals in relation to our services."
              )}
            </span>
            <span>
              {translate(
                "⑻ For other purposes incidental to the above purpose of use"
              )}
            </span>
          </div>
        </div>
        <div className="points">
          <div className="title">
            {translate(
              "4. Safety management and storage of personal information"
            )}
          </div>
          <div className="subtitle">
            {translate(
              "This site accurately processes the personal information provided, implements appropriate protection and safety measures to ensure the safety of the personal information held by this site, and loses, destroys, or falsifies the personal information. , No data leakage shall allowed."
            )}
          </div>
        </div>
        <div className="points">
          <div className="title">
            {translate("5. Sharing data with third party")}
          </div>
          <div className="subtitle">
            {translate(
              "As a general rule, we will not provide personal information to a third party without the consent of the customer, except in the following cases."
            )}
            <span>
              {translate(
                "⑴ When it is necessary to provide personal information to our third party in order to execute this service."
              )}
            </span>
            <span>{translate("⑵ When required by law.")}</span>
            <span>
              {translate(
                "⑶ When it is necessary to protect the life, body or property of a person."
              )}
            </span>
            <span>
              {translate(
                "⑷ For a national institution or a local public body or a person entrusted with it to carry out the affairs stipulated by law."
              )}
            </span>
            <span>{translate("⑸ When it is necessary to cooperate.")}</span>
            <span>
              {translate(
                "⑹ When submitting documents to a government office in connection with the performance of business."
              )}
            </span>
            <span>
              {translate(
                "⑺ When personal information is provided due to business growth or due to merger or other reasons."
              )}
            </span>
          </div>
        </div>
        <div className="points">
          <div className="title">
            {translate(
              "6. Disclosure / suspension / deletion of personal information"
            )}
          </div>
          <div className="subtitle">
            {translate(
              "If the person who is the subject of personal information makes a request for disclosure, correction, suspension of use, erasure, etc. of his / her personal information, after confirming that he / she is the person by an appropriate method, We will respond promptly unless it is permitted by law or business to refuse."
            )}
          </div>
        </div>
        <div className="points">
          <div className="title">
            {translate("7. Modification of privacy policy")}
          </div>
          <div className="subtitle">
            {translate(
              "This site may change its privacy policy without prior notice in response to changes in social conditions, technological progress, changes in various environments, etc. We will notify our users if any changes are made to this notice."
            )}
          </div>
        </div>
        <div className="points">
          <div className="title">{translate("8. Disclaimer")}</div>
          <div className="subtitle">
            {translate(
              "Although every effort has been made to ensure that the information posted on this site is correct, we are solely responsible for any damages (indirect damages, special damages, results) caused by using the information on this site due to amendments to the law or other causes. We do not incur any damage or incidental damage. Please use all information at your own risk."
            )}
          </div>
        </div>
        <div className="points">
          <div className="title">{translate("9. Inquiry")}</div>
          <div className="subtitle">
            {translate(
              "Regarding this privacy policy, Please do not hesitate to contact us from the inquiry form."
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export { Privacy }
