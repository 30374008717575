import { theme } from "@project/shared"
import React from "react"
import styled from "styled-components"

export interface IIssuesProps {
  title: string
  description: string
}

const CardWrapper = styled.div<any>`
  width: 360px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  padding: 30px 18px;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.cardGreyBg};
  @media (max-width: 786px) {
    align-items: start;
  }
`
const Title = styled.div`
  margin-right: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: ${theme.blueColouredText};
  white-space: break-spaces;
  @media (max-width: 1054px) {
    margin: 0;
    text-align: start;
    font-size: 22px;
  }
`
const Description = styled.div<any>`
  margin-top: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: ${theme.blackTextColor};
  @media (max-width: 1054px) {
    font-size: 18px;
    white-space: break-spaces;
  }
`

export const IssuesCard: React.FC<IIssuesProps> = ({ title, description }) => {
  return (
    <CardWrapper>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </CardWrapper>
  )
}
