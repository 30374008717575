import styled from "styled-components"

const Wrapper = styled.div`
  width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (max-width: 768px) {
    min-width: 100%;
  }

  .number {
    font-size: 56px;
    font-family: "DINOT", sans-serif;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: #eef1f5;
    position: absolute;
    top: -0.95em;
  }
`
const Avatar = styled.div`
  height: 280px;
  width: 280px;
  margin: auto;
  background: #eef1f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    height: 200px;
    width: 200px;
    img {
      height: 150px;
      width: 150px;
    }
  }
  @media (max-width: 1024px) {
    height: 180px;
    width: 180px;
    img {
      height: 120px;
      width: 120px;
    }
  }
`
const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #2b57a5;
  margin: 38px 0;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`
const Content = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 38px;
  white-space: pre-line;
  @media (max-width: 1054px) {
    font-size: 18px;
  }
`
interface Props {
  title?: string
  content?: string
  icon?: string
  number?: string | number
}
const IntroductionCard: React.FC<Props> = ({
  title,
  content,
  number,
  icon,
}) => {
  return (
    <Wrapper>
      <div>
        <span className="number">{number}</span>
        <Avatar>
          <img src={icon} height={"141px"} width={"224px"} />
        </Avatar>
      </div>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Wrapper>
  )
}
export default IntroductionCard
