import React from "react"
import { Button, ButtonProps } from "antd"
import styled from "styled-components"
import { ButtonType } from "antd/lib/button"
import { useTranslation } from "react-i18next"
import { theme } from "@project/shared"

type _ButtonType = ButtonType

export interface Props extends Omit<ButtonProps, "type"> {
  type?: _ButtonType
  transparent?: boolean
  fromLogin?: boolean
  height?: string
  width?: string
  title?: string
  showIcon?: boolean
  backgroundColor?: string
}

const ButtonContainer = styled(Button)<Props>`
  width: ${(props) => (!props?.width ? "400px" : props.width)};
  height: ${(props) => (!props?.height ? "80px" : props.height)};

  background: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : "linear-gradient(90deg, #2b57a5 0%, #3c70cd 100%) !important"};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  span {
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.04em;
    color: ${theme.base};
  }

  @media (max-width: 500px) {
    width: 300px;
    height: 50px;
    span {
      font-size: 20px;
    }
    .button-icon {
      height: 35px;
      width: 35px;
    }
  }
  @media (max-width: 375px) {
    width: 250px;
    span {
      font-size: 16px;
    }
  }
`

const ButtonComponent: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <ButtonContainer {...props} onClick={props.onClick}>
      <span> {t(props.title)}</span>
      {props.showIcon && (
        <img src={"/assets/icons/right-icon.svg"} className="button-icon" />
      )}
    </ButtonContainer>
  )
}

export { ButtonComponent }
