import { theme } from "@project/shared/src/theme"
import styled from "styled-components"
import { InformationCard } from "../../../molecules"
import { useRouter } from "next/router"

const Wrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  padding-top: 10em;
  @media (max-width: 786px) {
    padding: 60px 0 0 0;
  }
`

const ArrowBackward = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  img {
    transform: rotate(180deg);
  }
  p {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.08em;
    color: #3c70cd;
    margin-bottom: 0;
  }
  @media (max-width: 1439px) {
    height: 150px;
    margin-top: 20px;
    margin-left: 0;
    img {
      transform: rotate(270deg);
    }
    p {
      margin-top: -20px;
      padding-left: 120px;
      word-break: keep-all;
    }
  }
`

const Title = styled.div<any>`
  text-align: center;
  padding: 1em;
  @media (max-width: 786px) {
    white-space: break-spaces;
  }
  .title {
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: ${theme.blueColouredText};
    @media (max-width: 786px) {
      font-size: 21px;
    }
  }
  .sub-title {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: ${theme.darkBlueColouredText};
    @media (max-width: 786px) {
      font-size: 22px;
    }
  }
  .small-text {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.04em;
    @media (max-width: 786px) {
      font-size: 18px;
    }
  }
  .image-text-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    @media (max-width: 786px) {
      margin-bottom: 20px;
    }
    img {
      margin-left: 10px;
      @media (max-width: 500px) {
        width: 132px;
        height: 30px;
      }
      @media (max-width: 786px) {
        width: 152px;
        height: 50px;
      }
    }
  }
`
const ArrowForward = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  p {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.08em;
    color: #3c70cd;
    margin-bottom: 0;
  }
  @media (max-width: 1439px) {
    height: 150px;
    margin-left: 0;
    img {
      transform: rotate(90deg);
    }
    p {
      margin-top: -20px;
      padding-left: 120px;
      word-break: keep-all;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-inline: 75px;
  @media (max-width: 1439px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const Monitor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .logo {
    height: 54px;
  }
  .monitor {
    width: 100%;
  }
  @media (max-width: 1439px) {
    .logo {
      margin-bottom: 10px;
    }
  }
`

const ConnectCompanySection: React.FC = () => {
  const router = useRouter()
  return (
    <Wrapper id="connect-companies">
      <Title>
        <span className="title">{"ビジネス人材を求める企業"}</span>
        <span className="small-text">{"と"}</span>
        <span className="title">
          {"セールス\nポジションに強い人材紹介会社"}
        </span>
        <span className="small-text">{"\nを繋ぎ"}</span>
        <br />
        <br />
        <div className={"image-text-section"}>
          <span className="sub-title">
            {"新しい採用の形を実現"}
            <span className="small-text">{"する\n"}</span>
            <img src="/assets/icons/connectcompanylogo.svg" alt="" />
          </span>
        </div>
      </Title>
      <Content>
        <InformationCard
          title="人材紹介会社"
          description={"求職者に寄り添いながら\n幅広い選択肢の提示が可能"}
          linkText={
            router.pathname?.includes("company") && "人材紹介会社様はこちらから"
          }
        />
        <ArrowForward>
          <img src="/assets/images/arrow.png"></img>
          <p>{"人材紹介"}</p>
        </ArrowForward>
        <Monitor>
          <img src="/assets/icons/connectcompanylogo.svg" className="logo" />
          <img src="/assets/images/monitor.png" className="monitor"></img>
        </Monitor>
        <ArrowBackward>
          <img src="/assets/images/arrow.png" />
          <p>求人を公開</p>
        </ArrowBackward>
        <InformationCard
          title="求人企業様"
          description={"要望に合うビジネス人材の\n採用を効率よく行う事が可能"}
          linkText={
            router.pathname?.includes("agents") && "求人企業様はこちらから"
          }
        />
      </Content>
    </Wrapper>
  )
}

export { ConnectCompanySection }
